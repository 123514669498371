import { EditorCommand } from "../editor-command";

export class HistoryCommand extends EditorCommand {
    public direction: "undo" | "redo";

    constructor({ direction }: { direction: "undo" | "redo" }) {
        super();
        this.direction = direction;
    }

    public onExecute(): void | Promise<void> {
        // History Commands are directly executed by the commander
    }
}
