<template>
    <div class="landing container">
        <app-name></app-name>

        <p class="confirm-email">{{ $t("confirmEmail") }}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppName from "../../components/app-name/app-name.vue";

export default defineComponent({
    name: "confirm-email",
    components: {
        "app-name": AppName,
    },
});
</script>

<style lang="scss" scoped>
.landing {
    align-items: stretch;
    background-color: var(--background-color-secondary);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: 5%;

    .confirm-email {
        margin-top: auto;
        text-align: center;
        color: var(--text-color-secondary);
    }
}
</style>
