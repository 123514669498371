import { openDB, type IDBPDatabase } from "idb";
import type { SongDTO } from "../../common/dtos/song.dto";
import { parseSongDTO } from "../models/song/song-parser";
import type { Song } from "../models/song/song";

export const SONGS_STORE = "songs-test";

export type LocalSongRecord = {
    data: string;
    id: string;
};

export class LocalSongsApi {
    private _db: Promise<IDBPDatabase>;

    constructor() {
        this._db = openDB("my-guitar-tabs", 1, {
            upgrade(db, _oldVersion, _newVersion, _transaction, _event) {
                db.createObjectStore(SONGS_STORE);
            },
            // blocked(currentVersion, blockedVersion, event) {
            //     // …
            // },
            // blocking(currentVersion, blockedVersion, event) {
            //     // …
            // },
            // terminated() {
            //     // …
            // },
        });
    }

    public async getAll(): Promise<Song[]> {
        const db = await this.getDb();
        const rawSongs: LocalSongRecord[] = await db.getAll(SONGS_STORE);

        return rawSongs.map((s) => this.songRecordToSong(s));
    }

    public async get(id: string): Promise<Song | undefined> {
        const db = await this.getDb();
        const rawSong: LocalSongRecord | undefined = await db.get(SONGS_STORE, id);
        if (!rawSong) {
            return undefined;
        }

        return this.songRecordToSong(rawSong);
    }

    public async has(id: string): Promise<boolean> {
        const db = await this.getDb();
        const rawSong: LocalSongRecord | undefined = await db.get(SONGS_STORE, id);
        return Boolean(rawSong);
    }

    public async count(): Promise<number> {
        const db = await this.getDb();
        return db.count(SONGS_STORE);
    }

    public async setSong(id: string, song: SongDTO): Promise<void> {
        const db = await this.getDb();
        const songRecord = this.createSongRecord(id, song);
        await db.put(SONGS_STORE, songRecord, id);
    }

    public async setSongs(songs: Song[]): Promise<void> {
        const db = await this.getDb();

        const tx = db.transaction(SONGS_STORE, "readwrite");
        const store = tx.objectStore(SONGS_STORE);
        for (const song of songs) {
            const songRecord = this.createSongRecord(song.id, song.toDTO());
            store.put(songRecord, songRecord.id);
        }
        await tx.done;
    }

    public async delete(id: string): Promise<void> {
        const db = await this.getDb();
        await db.delete(SONGS_STORE, id);
    }

    public async clear(): Promise<void> {
        const db = await this.getDb();
        await db.clear(SONGS_STORE);
    }

    private getDb(): Promise<IDBPDatabase> {
        return this._db;
    }

    private createSongRecord(id: string, song: SongDTO): LocalSongRecord {
        return {
            data: JSON.stringify(song),
            id,
        };
    }

    private songRecordToSong(record: LocalSongRecord): Song {
        const songDTO = JSON.parse(record.data) as SongDTO;

        return parseSongDTO(record.id, songDTO);
    }
}
