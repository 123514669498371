import { MAX_STRINGS } from "../../../common/constants";
import type { TablaturePosition } from "../../interfaces/tablature-position";
import { isSamePosition } from "../../interfaces/tablature-position";
import type { TablatureRange } from "../../interfaces/tablature-range";
import type { EditorSelection } from "../editor-selection/editor-selection";

const TOUCH_HOLD_DELAY = 350;
const TOUCH_HOLD_VIBRATION = 30;

export class PointerManager {
    private editorSelection: EditorSelection;

    private _selectionAnchor: TablaturePosition | undefined;
    private previousPosition: TablaturePosition | undefined;

    private touchHoldTimeout: number | undefined;

    constructor(editorSelection: EditorSelection) {
        this.editorSelection = editorSelection;
    }

    private set selectionAnchor(selectionAnchor: TablaturePosition | undefined) {
        this._selectionAnchor = selectionAnchor;
        this.editorSelection.setSelectionInProgress(selectionAnchor !== undefined);
    }
    private get selectionAnchor(): TablaturePosition | undefined {
        return this._selectionAnchor;
    }

    public onPointerDown(payload: PointerEvent, position: TablaturePosition | undefined): void {
        if (this.isSelecting) {
            return;
        }

        if (!position) {
            this.editorSelection.clear();
        }

        if (payload.pointerType !== "touch") {
            this.selectionAnchor = position;
            this.editorSelection.set(position);
            this.previousPosition = position;
            return;
        } else {
            this.previousPosition = position;
        }

        this.clearTouchHoldTimeout();

        this.touchHoldTimeout = window.setTimeout(() => {
            if (navigator.vibrate) {
                navigator.vibrate(TOUCH_HOLD_VIBRATION);
            }
            this.touchHoldTimeout = undefined;
            this.startSelectionRange(position);
            this.updateSelectionRange(position);
        }, TOUCH_HOLD_DELAY);
    }

    public onDoubleClick(position: TablaturePosition, lineCount: number): void {
        const range: TablatureRange = {
            from: { eventIndex: position.eventIndex, stringIndex: 0 },
            to: { eventIndex: position.eventIndex, stringIndex: lineCount },
        };
        this.editorSelection.set(range);
    }

    public onPointerMove(payload: PointerEvent, position: TablaturePosition, maxSelectableEvent?: number): void {
        if (!position) {
            return;
        }
        if (payload.pointerType !== "touch") {
            if (this.editorSelection.hasRange() || !this.isSameAsAnchor(position)) {
                this.updateSelectionRange(position, maxSelectableEvent);
            }

            this.previousPosition = position;
            return;
        }

        if (!this.isSameAsPrevious(position)) {
            this.clearTouchHoldTimeout();
        }

        if (this.isSelecting) {
            this.updateSelectionRange(position, maxSelectableEvent);
        }
    }

    public onPointerUp(_payload: PointerEvent, position: TablaturePosition | undefined): void {
        this.clearTouchHoldTimeout();
        if (this.isSelecting) {
            this.selectionAnchor = undefined;
        } else {
            if (!position) {
                this.editorSelection.clear();
            }

            this.editorSelection.set(position);
            this.previousPosition = position;
        }
    }

    // This is probably not needed anymore
    public onPointerOut(): void {
        this.clearTouchHoldTimeout();
        if (this.isSelecting) {
            this.selectionAnchor = undefined;
        }
    }

    public onTouchMove(payload: TouchEvent): void {
        if (this.isSelecting) {
            // this causes intervention some times
            payload.preventDefault();
        } else {
            // Clear selection when scrolling
            this.editorSelection.clear();
        }
    }

    public clearTouchHoldTimeout(): void {
        clearTimeout(this.touchHoldTimeout);
        this.touchHoldTimeout = undefined;
    }

    private get isSelecting(): boolean {
        return this.selectionAnchor !== undefined;
    }

    private isSameAsAnchor(position: TablaturePosition): boolean {
        return this.isSameAsPosition(position, this.selectionAnchor);
    }

    private isSameAsPrevious(position: TablaturePosition): boolean {
        return this.isSameAsPosition(position, this.previousPosition);
    }

    private updateSelectionRange(hoverPosition: TablaturePosition | undefined, maxSelectableEvent?: number): void {
        if (!hoverPosition || !this.selectionAnchor) {
            return;
        }

        const isBackwardsSelection = hoverPosition.eventIndex < this.selectionAnchor.eventIndex;
        const newRange: TablatureRange = {
            from: isBackwardsSelection ? hoverPosition : this.selectionAnchor,
            to: isBackwardsSelection ? this.selectionAnchor : hoverPosition,
        };

        if (maxSelectableEvent && newRange.to.eventIndex > maxSelectableEvent) {
            newRange.to = {
                eventIndex: maxSelectableEvent,
                stringIndex: MAX_STRINGS,
            };
        }

        this.editorSelection.set(newRange);
    }

    private startSelectionRange(startPosition: TablaturePosition | undefined): void {
        this.selectionAnchor = startPosition;
        this.editorSelection.set(startPosition);
    }

    private isSameAsPosition(position: TablaturePosition, otherPosition: TablaturePosition | undefined): boolean {
        return Boolean(otherPosition && isSamePosition(position, otherPosition));
    }
}
