<template>
    <text class="tablature-text" x="0" :y="computedPadding" dy="1">{{ text }}</text>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: "event-annotation",
    props: {
        text: {
            type: String as PropType<string>,
            required: true,
        },
        padding: {
            type: Number as PropType<number>,
            required: true,
        },
    },
    computed: {
        computedPadding(): number {
            return -this.padding;
        },
    },
});
</script>
