<template>
    <Teleport to="body">
        <div class="modal-backdrop" @click="onBackdropClick">
            <div class="modal" tabindex="-1">
                <div class="modal-dialog" @click.stop>
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="title">
                                <slot name="header"></slot>
                            </div>
                            <button v-if="showCloseButton" type="button" class="btn-close" @click="close"></button>
                        </div>
                        <div class="modal-body">
                            <slot name="body"> </slot>
                        </div>
                        <div class="modal-footer">
                            <slot name="footer"> </slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "app-modal",
    props: {
        closeOnBackdropClick: {
            type: Boolean,
            default: true,
        },
        showCloseButton: {
            type: Boolean,
            default: true,
        },
    },
    emits: {
        close: () => true,
    },
    methods: {
        close() {
            this.$emit("close");
        },
        onBackdropClick() {
            if (this.closeOnBackdropClick) {
                this.close();
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
        display: block;
        overflow: hidden;

        .modal-dialog {
            margin-top: 50vh;
            transform: translateY(-50%);

            .modal-header {
                border-bottom: none;
                padding: 40px;
                padding-top: 20px;
                padding-bottom: 0;

                .title {
                    color: #545454;
                    font-size: 30px;
                    font-weight: 600;
                    flex-grow: 1;
                    text-align: center;
                }

                .btn-close {
                    width: 32px;
                    position: absolute;
                    right: 5px;
                    top: 13px;
                }
            }

            .modal-body {
                padding: 12px 40px;
            }

            .modal-footer {
                border-top: none;
                justify-content: center;
                padding-top: 0;
                padding-bottom: 20px;
            }
        }
    }
}
</style>
