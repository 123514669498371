export const KEYS = {
    HAMMER_PULL: "hammer-pull",
    SLIDE: "slide",
    BEND: "bend",
    PRE_BEND: "pre-bend",
    VIBRATO: "vibrato",
    BEND_DOWN: "bend-down",
    BAR: {
        LINE: "bar-line",
        REPEAT: "repeat-bar",
        DOUBLE: "double-bar",
    },
    UPSTROKE: "upstroke",
    DOWNSTROKE: "downstroke",
    TREMOLO_PICKING: "tremolo-picking",
    HARMONIC: "harmonic",
};
