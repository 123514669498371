<template>
    <loader :status="loadStatus" :loading-message="$t('loadingViewer')" :error-message="$t('failedToLoadSong')">
        <viewer v-if="song" :song="song"></viewer>
        <template #error>
            <load-failure></load-failure>
        </template>
    </loader>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { Song } from "../../models/song/song";
import Viewer from "./share-viewer.vue";
import Loader from "@components/loader/loader.vue";
import LoadFailure from "@components/load-failure/load-failure.vue";
import { getEditorStore } from "../../stores/editor.store";
import { getUserStore } from "../../stores/user.store";
import { LoadingStatus } from "../../../common/constants";

export default defineComponent({
    name: "share-loader",
    beforeRouteEnter(to, _from, next) {
        const songId: string = to.query.song as string;
        const uid: string = to.query.uid as string;

        // This is not available on beforeRouter guard
        const userStore = getUserStore();
        if (uid === userStore.user?.uid) {
            next({ name: "song", query: { song: songId } });
            return;
        }

        // Cannot use this.* here as it is beforeEnter
        const editorStore = getEditorStore();
        editorStore.loadSharedSong(songId, uid);
        next();
    },
    components: {
        loader: Loader,
        viewer: Viewer,
        "load-failure": LoadFailure,
    },
    computed: {
        song(): Song | undefined {
            return this.$store.editor.selectedSong as Song | undefined;
        },
        loadStatus(): LoadingStatus {
            return this.$store.editor.loadingStatus;
        },
    },
});
</script>
