import { SelectionCommand } from "./selection-command";

export type Direction = "up" | "down" | "left" | "right" | "bottom" | "top";

export class NavigateCommand extends SelectionCommand {
    private direction: Direction;

    constructor({ direction }: { direction: Direction }) {
        super();
        this.direction = direction;
    }

    public onExecute(): void {
        switch (this.direction) {
            case "up":
                this.goToRangeBegin();
                this.editorSelection.navigate.up();
                break;
            case "down":
                this.goToRangeEnd();
                this.editorSelection.navigate.down();
                break;
            case "left":
                this.goToRangeBegin();
                this.editorSelection.navigate.left();
                break;
            case "right":
                this.goToRangeEnd();
                this.editorSelection.navigate.right();
                break;
            case "top":
                this.goToRangeBegin();
                this.editorSelection.navigate.top();
                break;
            case "bottom":
                this.goToRangeEnd();
                this.editorSelection.navigate.bottom();
                break;
        }
    }

    private goToRangeEnd(): void {
        const range = this.editorSelection.range;
        if (range) {
            this.editorSelection.set(range.to);
        }
    }

    private goToRangeBegin(): void {
        const range = this.editorSelection.range;
        if (range) {
            this.editorSelection.set(range.from);
        }
    }
}
