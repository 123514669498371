<template>
    <app-modal @close="onCloseRenameNotebookModal">
        <template v-slot:header>{{ $t("renameNotebookTitle") }}</template>
        <template v-slot:body>
            <div class="rename-notebook-modal-container">
                <input
                    autofocus
                    type="text"
                    class="form-control rename-notebook"
                    :maxlength="maxLabelLength"
                    v-model="newNotebookName"
                    :placeholder="$t('renameNotebookPlaceholder')"
                    @keydown.enter="onRenameNotebook" />
                <div id="renameNotebookHelp" class="form-text">{{ $t("renameNotebookConstraints") }}</div>

                <button
                    class="btn btn-primary rename"
                    :class="{ disabled: !canRenameNotebook }"
                    @click="onRenameNotebook"
                    @keydown.enter="onRenameNotebook"
                    tabindex="0">
                    {{ $t("actionRenameNotebook") }}
                </button>
            </div>
        </template>
    </app-modal>
</template>

<script lang="ts">
import { MAX_LABEL_LENGTH } from "@common/constants";
import { SongStatus } from "@common/song-status";
import AppModal from "@components/app-modal/app-modal.vue";
import { defineComponent } from "vue";
import { api } from "../../apis/api";
import { Song } from "../../models/song/song";

export default defineComponent({
    name: "rename-notebook-modal",
    props: {
        selectedNotebook: {
            type: String,
            required: false,
            default: "",
        },
    },
    emits: {
        close: () => true,
    },
    components: {
        "app-modal": AppModal,
    },
    mounted() {
        this.newNotebookName = this.selectedNotebook;
    },
    data() {
        return {
            newNotebookName: "",
        };
    },
    computed: {
        notebooks(): string[] {
            return this.$store.config.userNotebooks.sort((a, b) => a.localeCompare(b));
        },
        canRenameNotebook(): boolean {
            if (this.newNotebookName.trim().length === 0) {
                return false;
            }

            if (this.notebooks.includes(this.newNotebookName)) {
                return false;
            }

            return true;
        },
        maxLabelLength(): number {
            return MAX_LABEL_LENGTH;
        },
        songsAtSelectedNotebook(): Song[] {
            const notebook = this.selectedNotebook;
            const normalNotebookSongs = this.$store.song.find({ status: SongStatus.Default, notebook });
            const pinnedNotebookSongs = this.$store.song.find({ status: SongStatus.Pinned, notebook });
            return normalNotebookSongs.concat(pinnedNotebookSongs);
        },
    },
    methods: {
        onCloseRenameNotebookModal(): void {
            this.$emit("close");
        },
        async onRenameNotebook() {
            if (!this.canRenameNotebook) {
                return;
            }

            api.log.track("renameNotebook");
            for (const song of this.songsAtSelectedNotebook) {
                const updatedSong = song.copy();
                updatedSong.metadata.labels = [this.newNotebookName];
                await this.$store.song.update(updatedSong);
            }

            this.$bus.emit("info", this.$t("actionCompleteRenameNotebook", { notebook: this.newNotebookName }));
            this.$router.push({ name: "notebook", params: { notebook: this.newNotebookName } });
        },
    },
});
</script>

<style lang="scss" scoped>
.rename-notebook-modal-container {
    .rename {
        margin-top: 15px;
        width: 100%;
    }
}
</style>
