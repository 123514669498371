import { isFret } from "@common/note";
import type { EditorCommand } from "../commander/commands/editor-command";
import { CopyCommand } from "../commander/commands/editor/copy";
import { CutCommand } from "../commander/commands/editor/cut";
import { DeleteNoteCommand } from "../commander/commands/editor/delete-note";
import { InsertEventCommand } from "../commander/commands/editor/insert-event";
import { PasteCommand } from "../commander/commands/editor/paste";
import { SetNoteFretCommand } from "../commander/commands/editor/set-note-fret";
import { ClearSelectedRange } from "../commander/commands/selection/clear-selected-range";
import { CursorCommand } from "../commander/commands/selection/cursor";
import { JumpChordsCommand } from "../commander/commands/selection/jump-chord";
import type { Direction } from "../commander/commands/selection/navigate";
import { NavigateCommand } from "../commander/commands/selection/navigate";
import { isRangeDirection, UpdateRange } from "../commander/commands/selection/update-range";
import { HistoryCommand } from "../commander/commands/utils/History";

export class PhysicalKeyboardInputManager {
    public getCommand(e: KeyboardEvent): EditorCommand | undefined {
        if (e.code === "Space") {
            return new CursorCommand({ direction: "next" });
        }

        if (e.key === "Enter") {
            return new InsertEventCommand();
        }

        if (e.key === "c" && e.ctrlKey) {
            return new CopyCommand();
        }

        if (e.key === "v" && e.ctrlKey) {
            return new PasteCommand();
        }

        if (e.key === "x" && e.ctrlKey) {
            return new CutCommand();
        }

        if (e.key.toLowerCase() === "z" && e.ctrlKey) {
            return new HistoryCommand({ direction: e.shiftKey ? "redo" : "undo" });
        }

        if (e.key.toLowerCase() === "r" && e.ctrlKey) {
            return new HistoryCommand({ direction: "redo" });
        }

        if (isFret(e.key)) {
            return new SetNoteFretCommand({
                fret: e.key,
                singleDigitInput: true,
            });
        }

        if (e.key === "x" || e.key === "X") {
            return new SetNoteFretCommand({ fret: "x" });
        }

        if (e.key.startsWith("Arrow")) {
            let direction = e.key.slice(5).toLowerCase() as Direction;

            if (e.shiftKey && isRangeDirection(direction)) {
                return new UpdateRange({ direction });
            }

            if (e.ctrlKey) {
                if (direction === "down") direction = "bottom";
                else if (direction === "up") direction = "top";
                else if (direction === "left") {
                    return new JumpChordsCommand({ direction: "previous" });
                } else if (direction === "right") {
                    return new JumpChordsCommand({ direction: "next" });
                }
            }

            return new NavigateCommand({ direction });
        }

        if (e.key === "Delete") {
            return new DeleteNoteCommand();
        }

        if (e.key === "Backspace") {
            return new DeleteNoteCommand({ backspace: true });
        }

        if (e.key === "Escape") {
            return new ClearSelectedRange();
        }
    }
}
