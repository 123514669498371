import { SelectionCommand } from "./selection-command";

export class JumpChordsCommand extends SelectionCommand {
    private direction: "next" | "previous";

    constructor({ direction }: { direction: "next" | "previous" }) {
        super();
        this.direction = direction;
    }

    public onExecute(): void {
        switch (this.direction) {
            case "next":
                this.editorSelection.cursor.nextChord();
                this.editorSelection.navigate.top();
                break;
            case "previous":
                this.editorSelection.cursor.previousChord();
                this.editorSelection.navigate.bottom();
                break;
        }
    }
}
