<template>
    <nav class="header navbar navbar-fixed-top">
        <div class="container container-fluid">
            <button
                class="btn bi bi-arrow-left back"
                :title="$t('back')"
                @click="onNavigateBack"
                @keydown.enter="onNavigateBack"
                tabindex="0"></button>
            <div class="toolbar d-flex align-items-center flex-grow-1 gap-1">
                <button
                    class="btn bi bi-sliders"
                    :title="$t('songSettings')"
                    @click="onDisplayAdjustmentsModal"
                    @keydown.enter="onDisplayAdjustmentsModal"
                    tabindex="0"></button>
                <button
                    class="btn btn-primary"
                    :class="{ disabled: isSaving }"
                    :title="doneButtonText"
                    @click="onEditingDone">
                    <output v-if="isSaving" class="spinner-border-overlay spinner-border-sm"></output>
                    <i class="bi bi-check-lg" :class="{ hide: isSaving }"></i>
                </button>
                <user-dropdown-menu></user-dropdown-menu>

                <zoom-controls></zoom-controls>
            </div>
        </div>
    </nav>

    <song-settings-modal
        v-if="isAdjustmentsModalVisible"
        :song="song"
        @close="onCloseAdjustmentsModal"></song-settings-modal>
</template>

<script lang="ts">
import SongActions from "@components/song-actions/song-actions.vue";
import ZoomControls from "@components/zoom-controls/zoom-controls.vue";
import UserDropdownMenu from "@components/user-dropdown-menu/user-dropdown-menu.vue";
import { defineComponent, PropType } from "vue";
import type { Song } from "../../../models/song/song";
import SongSettingsModal from "../song-settings-modal/song-settings-modal.vue";

export default defineComponent({
    name: "editor-header",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    data() {
        return {
            isSaving: false,
            isAdjustmentsModalVisible: false,
        };
    },
    components: {
        "song-actions": SongActions,
        "zoom-controls": ZoomControls,
        "user-dropdown-menu": UserDropdownMenu,
        "song-settings-modal": SongSettingsModal,
    },
    computed: {
        isNewSong(): boolean {
            return this.$store.editor.isNewSong;
        },
        loggedIn(): boolean {
            return this.$store.user.loggedIn;
        },
        doneButtonText(): string {
            return this.$t("doneEditing");
        },
        allowSave(): boolean {
            return this.isNewSong || this.$store.editor.hasSongChanged;
        },
    },
    methods: {
        onColorChange(color?: string): void {
            this.song.tags.color = color;
            this.$store.editor.markAsChanged();
        },
        onNavigateBack(): void {
            if (this.song.isInTrash()) {
                this.$router.push({ name: "trash" });
            } else if (this.song.isArchived()) {
                this.$router.push({ name: "archive" });
            } else {
                const notebook = (this.song.metadata.labels ?? [])[0];
                if (notebook) {
                    this.$router.push({
                        name: "notebook",
                        params: { notebook },
                    });
                } else {
                    this.$router.push({
                        name: "dashboard",
                    });
                }
            }
        },
        async onEditingDone(): Promise<void> {
            this.isSaving = true;

            try {
                if (this.allowSave) {
                    await this.$store.editor.saveSong();
                }
                this.$router.push({ name: "song", query: { song: this.$store.editor.selectedSongId } });
            } finally {
                this.isSaving = false;
            }
        },
        onDisplayAdjustmentsModal(): void {
            this.isAdjustmentsModalVisible = true;
        },
        onCloseAdjustmentsModal(): void {
            this.isAdjustmentsModalVisible = false;
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    background-color: var(--background-color-primary);
    border-bottom: solid 1px var(--gray-300);

    .container {
        column-gap: 25px;
        flex-wrap: nowrap;
        position: relative;

        .toolbar {
            justify-content: flex-end;
        }
    }
}
</style>
