<template>
    <nav class="header navbar navbar-fixed-top">
        <div class="container container-fluid">
            <button
                class="btn bi bi-arrow-left back"
                :title="$t('back')"
                @click="onNavigateBack"
                @keydown.enter="onNavigateBack"
                tabindex="0"></button>
            <div class="toolbar d-flex align-items-center flex-grow-1 gap-1">
                <song-actions :song="song" :songActionsOptions="songActionsOptions" displayMode="auto"></song-actions>

                <button v-if="song.isEditable()" class="btn btn-primary" @click="onEnableEdition" :title="$t('edit')">
                    <i class="bi bi-pencil"></i>
                </button>
                <user-dropdown-menu></user-dropdown-menu>

                <zoom-controls></zoom-controls>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import type { Song } from "../../../models/song/song";
import SongActions from "@components/song-actions/song-actions.vue";
import ZoomControls from "@components/zoom-controls/zoom-controls.vue";
import UserDropdownMenu from "@components/user-dropdown-menu/user-dropdown-menu.vue";
import { SongActionOptions, SongActionsType } from "../../../interfaces/song-actions";

export default defineComponent({
    name: "viewer-header",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    components: {
        "song-actions": SongActions,
        "zoom-controls": ZoomControls,
        "user-dropdown-menu": UserDropdownMenu,
    },
    data() {
        return {
            songActionsOptions: {
                edit: { hide: true },
                copy: { hide: true },
                delete: { hide: true },
                moveToNotebook: { hide: true },
            } as Record<SongActionsType, SongActionOptions>,
        };
    },
    methods: {
        onNavigateBack(): void {
            if (this.song.isInTrash()) {
                this.$router.push({ name: "trash" });
            } else if (this.song.isArchived()) {
                this.$router.push({ name: "archive" });
            } else {
                const notebook = (this.song.metadata.labels ?? [])[0];
                if (notebook) {
                    this.$router.push({
                        name: "notebook",
                        params: { notebook },
                    });
                } else {
                    this.$router.push({
                        name: "dashboard",
                    });
                }
            }
        },
        onEnableEdition(): void {
            this.$router.push({ name: "edit", query: { song: this.song.id } });
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    background-color: var(--background-color-primary);
    border-bottom: solid 1px var(--gray-300);

    .container {
        column-gap: 25px;
        flex-wrap: nowrap;
        position: relative;
    }
}
</style>
