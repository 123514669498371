import { defineStore } from "pinia";
import { api } from "../apis/api";

export const getZoomStore = defineStore("zoom", {
    state() {
        const zoomValue = api.localStorage.getNumber("zoomValue") ?? 1;

        // 'zoom' is not a standard css property, therefore it requires support check and overriding the typing.
        const supportsZoomProperty =
            (document.createElement("detect").style as CSSStyleDeclaration & { zoom: string }).zoom === "";
        const isFirefox = /firefox/i.test(navigator.userAgent);

        // Avoiding false positives by taking into account that Chrome on iOS includes 'safari' in its user agent string.
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        return {
            isSupported: !isFirefox && !isSafari && supportsZoomProperty,
            value: zoomValue,
            step: 0.05,
            min: 0.5,
            max: 1.5,
            default: 1,

            UNSUPPORTED_MESSAGE: "Zooming is not supported in this browser.",
        };
    },
    actions: {
        get(): number {
            return this.value;
        },
        getAsPercentage(): string {
            return Math.round(this.value * 100).toString() + "%";
        },
        in() {
            if (!this.isSupported) {
                this.$bus.emit("error", this.UNSUPPORTED_MESSAGE);
                return;
            }
            this._zoomStep(this.step);
        },
        out() {
            if (!this.isSupported) {
                this.$bus.emit("error", this.UNSUPPORTED_MESSAGE);
                return;
            }
            this._zoomStep(-this.step);
        },
        reset() {
            if (!this.isSupported) {
                this.$bus.emit("error", this.UNSUPPORTED_MESSAGE);
                return;
            }
            this.set(this.default);
        },
        set(newValue: number) {
            if (!this.isSupported) {
                this.$bus.emit("error", this.UNSUPPORTED_MESSAGE);
                return;
            }
            newValue = Math.max(this.min, Math.min(this.max, newValue));
            if (newValue === this.value) {
                return;
            }

            this.value = newValue;
            api.localStorage.set("zoomValue", newValue);
            this.apply();
        },
        // This is the method that applies the current zoom to the tablature element.
        // While it is usually automatically called when the zoom value changes, it can also be called manually.
        // E.g. when a new tablature element is loaded and the last zoom shall be applied to it by default.
        apply() {
            if (!this.isSupported) {
                this.$bus.emit("error", this.UNSUPPORTED_MESSAGE);
                return;
            }

            const tablatureElement: HTMLElement | null = document.querySelector(".tablature");
            if (!tablatureElement) {
                return;
            }

            const tablatureStyle = tablatureElement.style as CSSStyleDeclaration & { zoom: string };
            tablatureStyle.zoom = this.getAsPercentage();
        },

        _zoomStep(step: number) {
            if (!this.isSupported) {
                this.$bus.emit("error", this.UNSUPPORTED_MESSAGE);
                return;
            }
            const newValue = Math.round((this.value + step) * 100) / 100;
            this.set(newValue);
        },
    },
});

export type ZoomStore = ReturnType<typeof getZoomStore>;
