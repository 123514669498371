<template>
    <li
        v-if="!hide"
        class="btn"
        :class="{ disabled, flat }"
        :title="tooltip"
        @click.prevent="onDisplayMoveModal"
        @keydown.enter.prevent="onDisplayMoveModal"
        tabindex="0">
        <i class="bi" :class="{ 'bi-journal-bookmark-fill': hasLabel, 'bi-journal-bookmark': !hasLabel }"></i>
        <span v-if="!hideText">{{ text }}</span>
    </li>

    <song-action-move-modal v-if="isMoveModalVisible" :song="song" @close="onCloseMoveModal"></song-action-move-modal>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Song } from "../../../models/song/song";
import { SongActionOptions } from "../../../interfaces/song-actions";
import { shouldHideAction } from "../../../utils/song-actions-utils";
import SongActionMoveModal from "./song-action-move-modal.vue";

export default defineComponent({
    name: "song-action-move",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        songActionOptions: {
            type: Object as PropType<SongActionOptions>,
            default: () => ({}),
        },
        flat: {
            type: Boolean,
            default: false,
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        "song-action-move-modal": SongActionMoveModal,
    },
    data() {
        return {
            isMoveModalVisible: false,
        };
    },
    computed: {
        hide(): boolean {
            return shouldHideAction("moveToNotebook", this.song, this.songActionOptions);
        },
        disabled(): boolean {
            return this.songActionOptions.disabled === true;
        },
        text(): string {
            return this.$t("actionMoveToNotebook");
        },
        tooltip(): string {
            return this.text;
        },
        hasLabel(): boolean {
            return (this.song.metadata.labels ?? []).length > 0;
        },
    },
    methods: {
        onDisplayMoveModal(): void {
            if (this.disabled) {
                return;
            }

            this.isMoveModalVisible = true;
        },
        onCloseMoveModal(): void {
            this.isMoveModalVisible = false;
        },
    },
});
</script>
