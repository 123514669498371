<template>
    <form class="row g-3" @click.prevent>
        <legend>{{ $t("settingsApp") }}</legend>
        <div class="form-group col-12 col-md-6">
            <div class="form-group col-12">
                <label for="appPlatform" class="form-label">{{ $t("settingsPlatform") }}:</label>
                <input id="appPlatform" type="text" class="form-control" :value="appPlatform" disabled />
            </div>
        </div>
        <div class="form-group col-12 col-md-6">
            <div class="form-group col-12">
                <label for="appVersion" class="form-label">{{ $t("settingsVersion") }}:</label>
                <input id="appVersion" type="text" class="form-control" :value="appVersion" disabled />
                <div v-if="!needsReload" class="form-text">
                    {{ $t("settingsUsingLatestVersion") }}
                </div>
            </div>
        </div>
        <div v-if="needsReload" class="form-group col-12">
            <button @click="reloadApp" class="btn btn-warning">
                <i class="bi bi-arrow-repeat"></i>{{ $t("settingsUpdateApp") }}
            </button>
            <div class="form-text text-center">
                {{ $t("settingsNewVersionAvaiable") }}
            </div>
        </div>
    </form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AppVersion } from "../../../stores/config.store";

export default defineComponent({
    name: "settings-app",
    computed: {
        needsReload(): boolean {
            return this.$store.user.needReload;
        },
        appPlatform(): AppVersion {
            return this.$store.config.appVersion;
        },
        appVersion(): string {
            return APP_VERSION ? `v${APP_VERSION}` : "";
        },
    },
    methods: {
        async reloadApp(): Promise<void> {
            await this.$store.user.reload();
        },
    },
});
</script>

<style lang="scss" scoped>
.btn {
    height: auto;
    white-space: wrap;
    width: 100%;
}

legend {
    margin-top: 0;
}
</style>
