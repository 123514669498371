<template>
    <loader :status="loadStatus" :loading-message="$t('loadingSession')" :error-message="$t('failedToLoadSession')">
        <router-view :key="routePath"></router-view>
        <toast></toast>
    </loader>
</template>

<script lang="ts">
import { LoadingStatus } from "@common/constants";
import Loader from "@components/loader/loader.vue";
import Toast from "@components/toast/toast.vue";
import { defineComponent } from "vue";
import { api } from "./apis/api";

export default defineComponent({
    name: "app",
    async mounted(): Promise<void> {
        window.addEventListener("beforeinstallprompt", (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();

            // Stash the event so it can be triggered later.
            this.$store.install.deferredPrompt = e as any;
        });

        document.addEventListener("visibilitychange", (event) => {
            if (document.visibilityState == "visible") {
                // Relocks screen if needed
                this.$store.config.wakeLockScreenRetry();
                api.syncApi.sync();
            }
        });
        api.log.trackVersion();
    },
    components: {
        toast: Toast,
        loader: Loader,
    },
    computed: {
        loadStatus(): LoadingStatus {
            return this.$store.user.loadStatus;
        },
        routePath(): string {
            return (this as any).$route.path as string;
        },
    },
});
</script>
