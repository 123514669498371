import { Base64 } from "js-base64";
import type { ClipboardEvents, ClipboardNote } from "../../../../interfaces/clipboard";
import type { Song } from "../../../../models/song/song";
import { getClipboardStore } from "../../../../stores/clipboard.store";
import type { EditorSelection } from "../../../editor-selection/editor-selection";
import { EditorCommand } from "../editor-command";

export class CopyCommand extends EditorCommand {
    public get modifiesSong(): boolean {
        return false;
    }

    public async onExecute(): Promise<void> {
        await this.copyToClipboard();
    }

    protected async copyToClipboard(): Promise<boolean> {
        const clipboardStore = getClipboardStore();

        if (!this.editorSelection.hasRange()) {
            if (this.isChordCompatible(this.song, this.editorSelection)) {
                const positionSelection = this.editorSelection.getPosition();
                if (!positionSelection) throw new Error("Fatal error on copy command");
                const note = this.tablature.getNote(positionSelection);
                const data: ClipboardNote = {
                    type: "note",
                    note: note,
                };
                return await clipboardStore.writeText(this.serializeClipboardString(data));
            }
        }

        const rangeSelection = this.editorSelection.range;
        if (!rangeSelection) return false;
        const data: ClipboardEvents = {
            type: "events",
            events: this.tablature.getEvents(rangeSelection.from.eventIndex, rangeSelection.to.eventIndex),
        };
        return await clipboardStore.writeText(this.serializeClipboardString(data));
    }

    private isChordCompatible(song: Song, editorSelection: EditorSelection): boolean {
        const selectionPosition = editorSelection.getPosition();
        if (!selectionPosition) return false;
        return this.isChordCompatibleEvent(selectionPosition.eventIndex, song);
    }

    private isChordCompatibleEvent(eventIndex: number, song: Song): boolean {
        const eventType = song.tablature.getEventType(eventIndex);
        return ["chord", undefined].includes(eventType);
    }

    private serializeClipboardString(data: ClipboardNote | ClipboardEvents): string {
        const jsonStr = JSON.stringify(data);
        return Base64.encode(jsonStr);
    }
}
