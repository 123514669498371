import { SelectionCommand } from "./selection-command";

export type RangeDirection = "left" | "right";

export function isRangeDirection(value: string): value is RangeDirection {
    return value === "left" || value === "right";
}

export class UpdateRange extends SelectionCommand {
    private direction: RangeDirection;

    constructor({ direction }: { direction: RangeDirection }) {
        super();
        this.direction = direction;
    }

    public onExecute(): void {
        const anchor = this.editorSelection.getPosition();
        if (!anchor) return;

        const range = this.editorSelection.range || {
            from: { ...anchor },
            to: { ...anchor },
        };

        const newRange = { ...range };

        if (this.direction === "left") {
            if (range.to.eventIndex === anchor.eventIndex) {
                newRange.from.eventIndex -= 1;
            } else {
                newRange.to.eventIndex -= 1;
            }
        } else {
            if (range.from.eventIndex === anchor.eventIndex) {
                newRange.to.eventIndex += 1;
            } else {
                newRange.from.eventIndex += 1;
            }
        }

        this.editorSelection.updateRange(newRange);
    }
}
