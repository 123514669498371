import type { SongDTO } from "../../common/dtos/song.dto";
import type { SongVisibility } from "../../common/song-metadata";
import type { Song } from "../models/song/song";
import { ApiError } from "./errors";
import type { LocalSongsApi } from "./local-songs-api";
import type { RemoteSongsApi } from "./remote-songs-api";
import { validateSongId, validateSongInput } from "@common/validators/song-validator";

export type SongApiEvent = "delete" | "update";

/** Api for interacting with songs used by the web */
export class SongsApi extends EventTarget {
    private localSongApi: LocalSongsApi;
    private remoteSongApi: RemoteSongsApi;

    constructor(localSongApi: LocalSongsApi, remoteSongApi: RemoteSongsApi) {
        super();
        this.localSongApi = localSongApi;
        this.remoteSongApi = remoteSongApi;
    }

    public getAll(): Promise<Song[]> {
        return this.localSongApi.getAll();
    }

    public async get(id: string): Promise<Song> {
        const song = await this.localSongApi.get(id);
        if (!song) {
            throw new ApiError("song-not-found", `Song ${id} not found`);
        }
        return song;
    }

    public async count(): Promise<number> {
        return this.localSongApi.count();
    }

    public async getShared(id: string, uid: string): Promise<Song> {
        const song = await this.remoteSongApi.get(id, uid);
        if (!song) {
            throw new ApiError("song-not-found", `Song ${id} not found`);
        }
        return song;
    }

    public async setSong(id: string, song: SongDTO): Promise<void> {
        await validateSongInput(song);
        id = await validateSongId(id);

        await this.localSongApi.setSong(id, song);
        this.triggerEvent("update", id);
    }

    public async delete(id: string): Promise<void> {
        await this.localSongApi.delete(id);
        this.triggerEvent("delete", id);
    }

    public async updateVisibility(songId: string, visibility: SongVisibility): Promise<void> {
        // This one does not trigger event, as it directly interacts with remote
        await this.remoteSongApi.updateVisibility(songId, visibility);
        const storedSong = await this.get(songId);
        const updatedSong = storedSong.toDTO();
        updatedSong.metadata.visibility = visibility;
        await this.localSongApi.setSong(songId, updatedSong);
    }

    private triggerEvent(ev: SongApiEvent, id: string) {
        const event = new CustomEvent(ev, { detail: { id } });
        this.dispatchEvent(event);
    }
}
