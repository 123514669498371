import type { TablaturePosition } from "../../interfaces/tablature-position";
import type { TablatureRange } from "../../interfaces/tablature-range";
import type { Song } from "../../models/song/song";
import type { EditorSelection } from "../editor-selection/editor-selection";
import type { EditorCommand } from "./commands/editor-command";
import { HistoryCommand } from "./commands/utils/History";

type Command = EditorCommand;

interface CommandState {
    command: Command;
    song: Song; // Holds a copy of the song before the command was executed
    selection: TablaturePosition | TablatureRange | undefined;
    previousPosition: TablaturePosition | undefined;
}

export class Commander {
    private readonly MAX_UNDO_HISTORY = 300;
    private song: Song;
    private editorSelection: EditorSelection;
    private commandHistory: CommandState[] = [];
    private undoneCommandHistory: CommandState[] = [];

    constructor(song: Song, selection: EditorSelection) {
        this.song = song;
        this.editorSelection = selection;
    }

    public async handleInputCommand(inputEvent: Command): Promise<void> {
        if (inputEvent instanceof HistoryCommand) {
            this.handleHistoryCommand(inputEvent);
            return;
        }
        this.undoneCommandHistory = [];
        this.executeInputCommand(inputEvent);
    }

    private handleHistoryCommand(command: HistoryCommand) {
        if (command.direction === "undo") {
            this.undo();
        } else {
            this.redo();
        }
    }

    private undo() {
        const lastCommand = this.commandHistory.pop();
        if (lastCommand) {
            this.undoneCommandHistory.push(lastCommand);
            this.song.tablature = lastCommand.song.tablature;
            this.editorSelection.setState(lastCommand.previousPosition, lastCommand.selection);
        }
    }

    private redo() {
        const lastUndoneCommand = this.undoneCommandHistory.pop();
        if (lastUndoneCommand) {
            this.editorSelection.setState(lastUndoneCommand.previousPosition, lastUndoneCommand.selection);
            this.executeRedoCommand(lastUndoneCommand.command);
        }
    }

    private async executeRedoCommand(command: Command) {
        if (command.modifiesSong) {
            this.addCommandToHistory(command);
        }

        await command.redo({ song: this.song, editorSelection: this.editorSelection });
    }

    private async executeInputCommand(command: Command) {
        if (command.modifiesSong) {
            this.addCommandToHistory(command);
        }

        await command.execute({ song: this.song, editorSelection: this.editorSelection });
    }

    private addCommandToHistory(command: Command): void {
        const song = this.song.copy();
        const selection = this.editorSelection.hasRange()
            ? this.editorSelection.range
            : this.editorSelection.getPosition();

        this.commandHistory.push({ command, song, selection, previousPosition: this.editorSelection.previousPosition });
        if (this.commandHistory.length > this.MAX_UNDO_HISTORY) {
            this.commandHistory.shift();
        }
    }
}
