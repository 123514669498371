<template>
    <li
        v-if="!hide"
        class="btn"
        :class="{ disabled, flat }"
        :title="tooltip"
        @click.prevent="onToggleTrash"
        @keydown.enter.prevent="onToggleTrash"
        tabindex="0">
        <i class="bi" :class="{ 'bi bi-arrow-counterclockwise': isInTrash, 'bi-trash': !isInTrash }"></i>
        <span v-if="!hideText">{{ text }}</span>
    </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { SongStatus } from "@common/song-status";
import { Song } from "../../../models/song/song";
import { api } from "../../../apis/api";
import { SongActionOptions } from "../../../interfaces/song-actions";
import { isWhitespaceString } from "../../../utils/utils";
import { shouldHideAction } from "../../../utils/song-actions-utils";

export default defineComponent({
    name: "song-action-trash",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        songActionOptions: {
            type: Object as PropType<SongActionOptions>,
            default: () => ({}),
        },
        flat: {
            type: Boolean,
            default: false,
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            actionInProgress: false,
        };
    },
    computed: {
        hide(): boolean {
            return shouldHideAction("trash", this.song, this.songActionOptions);
        },
        disabled(): boolean {
            return this.actionInProgress || this.songActionOptions.disabled === true;
        },
        isInTrash(): boolean {
            return this.song.isInTrash();
        },
        text(): string {
            return this.isInTrash ? this.$t("actionMoveToSongs") : this.$t("actionTrash");
        },
        tooltip(): string {
            return this.text;
        },
        songName(): string {
            return isWhitespaceString(this.song.title) ? this.$t("songUntitled") : this.song.title;
        },
        currentRoute(): string {
            return this.$router.currentRoute.value.name as string;
        },
    },
    methods: {
        async onToggleTrash(): Promise<void> {
            if (this.disabled) {
                return;
            }

            api.log.track("toggleTrash");
            const originalStatus = this.song.status;
            const updatedSong = this.song.copy();
            updatedSong.status = this.song.isInTrash() ? SongStatus.Default : SongStatus.Trashed;

            this.actionInProgress = true;
            await this.$store.song.update(updatedSong);
            this.actionInProgress = false;

            if (updatedSong.isInTrash() && ["song", "edit"].includes(this.currentRoute)) {
                this.$router.push({ name: this.getPreviousMainRouteName(originalStatus) });
            }
            const messageKey = updatedSong.isInTrash() ? "actionCompleteTrash" : "actionCompleteMoveToSongs";
            this.$bus.emit("info", this.$t(messageKey, { songName: this.songName }));
        },
        getPreviousMainRouteName(previousSongStatus: SongStatus): string {
            if (previousSongStatus === SongStatus.Trashed) {
                return "trash";
            }

            if (previousSongStatus === SongStatus.Archived) {
                return "archive";
            }

            return "dashboard";
        },
    },
});
</script>
