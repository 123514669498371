<template>
    <nav class="footer navbar fixed-bottom">
        <div class="container" v-if="showCreateSong">
            <a
                class="btn btn-primary btn-action create-song"
                href="#"
                :aria-label="$t('createNewSong')"
                :title="$t('createNewSong')"
                @click.prevent="createNewSong"
                @keydown.enter.prevent="createNewSong"
                tabindex="0">
                <i class="bi bi-plus-lg"></i> <span class="d-none d-sm-flex">{{ $t("createNewSong") }}</span>
            </a>
        </div>

        <div class="container">
            <div class="navigation-container d-flex gap-1">
                <router-link class="footer-link flex-column" :to="{ name: 'dashboard' }">
                    <i class="bi bi-music-note footer-link-icon hide-on-active"></i>
                    <i class="bi bi-music-note footer-link-icon hide-on-inactive"></i
                    ><span class="footer-link-text">{{ $t("linkSongs") }}</span>
                </router-link>
                <router-link class="footer-link flex-column" :to="{ name: 'archive' }">
                    <i class="bi bi-archive footer-link-icon hide-on-active"></i>
                    <i class="bi bi-archive-fill footer-link-icon hide-on-inactive"></i
                    ><span class="footer-link-text">{{ $t("linkArchive") }}</span>
                </router-link>
                <router-link class="footer-link flex-column" :to="{ name: 'trash' }">
                    <i class="bi bi-trash footer-link-icon hide-on-active"></i>
                    <i class="bi bi-trash-fill footer-link-icon hide-on-inactive"></i
                    ><span class="footer-link-text">{{ $t("linkTrash") }}</span></router-link
                >
                <button v-if="shouldShowInstall" class="footer-link flex-column" @click="install">
                    <i class="bi bi-download footer-link-icon"></i
                    ><span class="footer-link-text">{{ $t("linkInstall") }}</span>
                </button>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "app-footer",
    props: {
        showCreateSong: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        shouldShowInstall(): boolean {
            return this.$store.install.shouldShow();
        },
        canAddSong(): boolean {
            return this.$store.song.canAddSong;
        },
    },
    methods: {
        async createNewSong(): Promise<void> {
            if (!this.canAddSong) {
                this.$bus.emit("error", this.$t("songsLimitError"));
                return;
            }

            this.$router.push({
                name: "create",
            });
        },
        install(): void {
            this.$store.install.prompt();
        },
    },
});
</script>

<style lang="scss" scoped>
.footer {
    background-color: var(--background-color-secondary);
    border-top: solid 1px var(--gray-300);
    padding: 5px;

    @media (min-width: 1400px) {
        display: none;
    }

    .container {
        position: relative;
        justify-content: center;

        .create-song {
            position: absolute;
            right: 20px;
            top: 0px;
            transform: translateY(calc(-100% - 10px));
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

            &:focus {
                box-shadow: inset 0 0 0 2px rgb(255 160 0 / 50%);
            }
        }

        .navigation-container {
            .footer-link {
                align-items: center;
                border-radius: 8px;
                color: var(--text-color) !important;
                display: flex;
                padding: 5px 10px;
                text-decoration: none;
                transition: background-color 0.2s ease-in-out;
                width: 90px;
                min-width: 90px;

                &:not(.disabled) {
                    &:hover {
                        cursor: pointer;

                        .footer-link-icon {
                            background-color: var(--gray-100);
                        }
                    }
                }

                &.disabled {
                    cursor: default;
                    opacity: 0.4;
                    pointer-events: none;
                }

                &:focus {
                    border-color: none;
                    box-shadow: inset 0 0 0 2px rgb(133 171 231);
                    outline: 0;
                }

                &.router-link-active {
                    .footer-link-icon {
                        background-color: var(--secondary-color-50);

                        &.hide-on-active {
                            display: none;
                        }
                    }

                    .footer-link-text {
                        font-weight: 500;
                    }

                    &:not(.disabled) {
                        &:hover {
                            .footer-link-icon {
                                background-color: var(--secondary-color-100);
                            }
                        }
                    }
                }

                &:not(.router-link-active) {
                    .footer-link-icon {
                        &.hide-on-inactive {
                            display: none;
                        }
                    }
                }

                .footer-link-icon {
                    width: 130%;
                    border-radius: 15px;
                    font-size: 20px;
                    text-align: center;
                }

                .footer-link-text {
                    font-size: 14px;
                    text-wrap: nowrap;
                }
            }
        }
    }
}
</style>
