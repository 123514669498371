<template>
    <div class="editor-song container">
        <form class="d-flex flex-column mt-lg-0">
            <input
                class="form-control title"
                type="text"
                tabindex="0"
                v-model="song.title"
                :maxlength="maxStringLength"
                :placeholder="enableInputs ? $t('songTitle') : ''"
                :readonly="!enableInputs"
                @change="onTagChange"
                @focus="onTagFocus" />
            <input
                class="form-control info"
                type="text"
                tabindex="0"
                v-model="song.tags.comment"
                :maxlength="maxStringLength"
                :placeholder="enableInputs ? $t('songExtraInfo') : ''"
                :readonly="!enableInputs"
                @change="onTagChange"
                @focus="onTagFocus" />
        </form>
    </div>
</template>

<script lang="ts">
import type { Song } from "models/song/song";
import { defineComponent, PropType } from "vue";
import { MAX_STRING_LENGTH } from "../../../common/constants";

export default defineComponent({
    name: "editor-tags",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        enableInputs: {
            type: Boolean as PropType<Boolean>,
            required: true,
        },
    },
    emits: {
        tagChange: () => true,
        tagFocus: () => true,
    },
    computed: {
        maxStringLength(): number {
            return MAX_STRING_LENGTH;
        },
    },
    methods: {
        onTagChange(): void {
            this.$emit("tagChange");
        },
        onTagFocus(): void {
            this.$emit("tagFocus");
        },
    },
});
</script>

<style lang="scss" scoped>
.editor-song {
    height: 110px;
    width: 100%;

    input {
        background: none;
        border: none;
        border-radius: 0;
        padding: 0;
        width: auto;

        &::placeholder {
            opacity: 0.65;
        }

        &:read-only {
            cursor: text;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .title,
    .info {
        text-align: center;
        margin: 5px;
    }

    .title {
        font-size: 28px;
    }

    .info {
        font-size: 1.25rem;
    }
}
</style>
