<template>
    <div class="editor workspace" ref="editorContainer">
        <editor-header :song="song"></editor-header>

        <!-- The id is used to access this element and correct its scrollLeft value to 0 
            when the browser auto-scrolls to keep an input visible during annotation edits 
            near the editor's boundaries. -->
        <main class="tablature" id="tablature-editor">
            <editor-tags
                :song="song"
                :enable-inputs="true"
                @tag-change="markSongAsChanged()"
                @tag-focus="clearSelection"></editor-tags>
            <editor-tablature :song="song" :can-edit="true" @update-annotation="updateAnnotation"></editor-tablature>
        </main>

        <keyboard
            :hide-suggestions="hideSuggestions"
            :last-fret="$store.editor.lastModifiedNote?.note"
            :disable-modifiers="shouldDisableModifiers"
            :class="{ hidden: !hasSelection }"
            @keyPress="virtualKeyboardInputHandler($event)"
            @apply-suggestion="onApplySuggestion">
        </keyboard>
    </div>
</template>

<script lang="ts">
import Keyboard from "@components/keyboard/keyboard.vue";
import { defineComponent, PropType } from "vue";
import { NoteModifier } from "../../../common/note";
import { ChordModifier } from "../../../common/tablature-event";
import { isSamePosition, TablaturePosition } from "../../interfaces/tablature-position";
import type { Song } from "../../models/song/song";
import { Commander } from "../../services/commander/commander";
import { EditorCommand } from "../../services/commander/commands/editor-command";
import { ApplyModifierSuggestion } from "../../services/commander/commands/editor/apply-modifier-suggestion";
import { SetEventAnnotation } from "../../services/commander/commands/editor/set-event-annotation";
import type { EditorSelection } from "../../services/editor-selection/editor-selection";
import { PhysicalKeyboardInputManager } from "../../services/input-manager/physical-keyboard-input-manager";
import { VirtualKeyboardInputManager } from "../../services/input-manager/virtual-keyboard-input-manager";
import EditorHeader from "./editor-header/editor-header.vue";
import EditorTablature from "./editor-tablature.vue";
import EditorTags from "./editor-tags.vue";

export default defineComponent({
    name: "editor",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    data() {
        return {
            keyDownBind: undefined as any,
            scrollBind: undefined as any,
            inputManagers: {
                physicalKeyboard: new PhysicalKeyboardInputManager(),
                virtualKeyboard: new VirtualKeyboardInputManager(),
            },
        };
    },
    components: {
        "editor-header": EditorHeader,
        "editor-tags": EditorTags,
        "editor-tablature": EditorTablature,
        keyboard: Keyboard,
    },
    mounted(): void {
        if (!this.song.isEditable()) {
            this.$bus.emit("error", `${this.song.title || "Song"} cannot be edited`);
            if (this.song?.id) {
                this.$router.push({ name: "song", query: { song: this.song.id } });
            }
            return;
        }

        this.keyDownBind = this.physicalKeyboardInputHandler;
        window.addEventListener("keydown", this.keyDownBind);

        this.editorContainer.scrollTop = this.$store.editor.scrollPosition;
        this.scrollBind = this.onScroll;
        this.editorContainer.addEventListener("scroll", this.scrollBind);

        if (this.$store.zoom.isSupported) {
            this.$store.zoom.apply();
        }
    },
    beforeUnmount(): void {
        this.editorContainer.removeEventListener("scroll", this.scrollBind);
    },
    unmounted(): void {
        window.removeEventListener("keydown", this.keyDownBind);
    },
    computed: {
        editorContainer(): HTMLElement {
            return this.$refs.editorContainer as any;
        },
        editorSelection(): EditorSelection {
            return this.$store.editor.selection as EditorSelection;
        },
        hasSelection(): boolean {
            return this.editorSelection.hasSelection();
        },
        commander(): Commander {
            return new Commander(this.song, this.$store.editor.selection as EditorSelection);
        },
        isTablatureFocused(): boolean {
            return Boolean(this.editorSelection.getPosition());
        },
        previousSelection(): TablaturePosition | undefined {
            return this.editorSelection.previousPosition;
        },
        hideSuggestions(): boolean {
            if (
                !this.previousSelection ||
                !this.$store.editor.showSuggestions ||
                !this.$store.editor.lastModifiedNote
            ) {
                return true;
            }
            if (this.$store.editor.lastModifiedNote.note === "x") return true;

            return !isSamePosition(this.previousSelection, this.$store.editor.lastModifiedNote.position);
        },
        shouldDisableModifiers(): boolean {
            const position = this.editorSelection.getPosition();

            if (!position || this.editorSelection.hasRange()) {
                return true;
            }

            const note = this.song.tablature.getNote(position);
            return !note || note.fret === "x";
        },
    },
    methods: {
        async updateAnnotation(index: number, value: string | undefined): Promise<void> {
            const setAnnotationCommand = new SetEventAnnotation({ eventIndex: index, annotation: value });
            await this.runCommand(setAnnotationCommand);
        },
        clearSelection() {
            this.$store.editor.clearSelection();
        },
        async physicalKeyboardInputHandler(e: KeyboardEvent): Promise<void> {
            // https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event#keydown_events_with_ime
            if (e.isComposing || e.keyCode === 229 || e.key === undefined) {
                return;
            }

            if (!this.isTablatureFocused) {
                return;
            }

            const keyboardInput = this.inputManagers.physicalKeyboard.getCommand(e);
            if (keyboardInput) {
                e.preventDefault();
                await this.runCommand(keyboardInput);
            }
        },
        async virtualKeyboardInputHandler(keypress: string): Promise<void> {
            if (!this.isTablatureFocused) return;
            const keyboardInput = this.inputManagers.virtualKeyboard.getCommand(keypress);
            if (keyboardInput) await this.runCommand(keyboardInput);
        },
        async onApplySuggestion(suggestion: string): Promise<void> {
            const modifierCommand = new ApplyModifierSuggestion({
                modifier: suggestion as NoteModifier | ChordModifier,
            });
            await this.runCommand(modifierCommand);
        },
        async runCommand(command: EditorCommand): Promise<void> {
            await this.commander.handleInputCommand(command);
            if (command.modifiesSong) {
                this.markSongAsChanged();
            }
        },
        markSongAsChanged(): void {
            this.$store.editor.markAsChanged();
        },
        onScroll(): void {
            this.$store.editor.scrollPosition = this.editorContainer.scrollTop;
        },
    },
});
</script>

<style lang="scss" scoped>
.editor {
    background-color: var(--background-color-secondary);
    height: calc(100% - 62px);
    margin-top: 62px;
    overflow: auto;
    padding-top: 60px;
    padding-bottom: 250px;

    .tablature {
        align-content: flex-start;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .clear-space {
        height: 200px;
    }
}
</style>
