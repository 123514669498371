<template>
    <div class="annotation-input-container">
        <button @click="onClick" type="button" class="btn btn-hover-secondary-light add-annotation">
            <i class="bi bi-fonts"></i>
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Point } from "../../../models/point";
import { Geometry } from "../../../services/renderer/geometry";

export default defineComponent({
    name: "add-annotation-button",
    props: {
        position: {
            type: Point,
            required: true,
        },
        geometry: {
            type: Geometry,
            required: true,
        },
    },
    emits: {
        addAnnotation: () => true,
    },
    computed: {
        padding(): Point {
            const paddingY = this.geometry.style.annotationPadding;
            return new Point(0, -paddingY);
        },
        paddedPosition(): Point {
            return this.position.plus(this.padding);
        },
        positionTop(): string {
            return `${this.paddedPosition.y}px`;
        },
        positionLeft(): string {
            return `${this.paddedPosition.x}px`;
        },
    },
    methods: {
        onClick(): void {
            this.$emit("addAnnotation");
        },
    },
});
</script>

<style lang="scss" scoped>
.annotation-input-container {
    position: absolute;
    top: v-bind("positionTop");
    left: v-bind("positionLeft");
    transform: translate(-50%, -50%);

    .add-annotation {
        color: var(--text-color-secondary) !important;
    }
}
</style>
