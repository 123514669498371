<template>
    <div class="col song song-preview-item">
        <div
            class="card shadow-sm"
            :class="{
                coloured: !!song.tags.color,
            }"
            :style="{
                'background-color': song.tags.color,
            }">
            <router-link :to="{ name: 'song', query: { song: song.id } }" custom v-slot="{ navigate }">
                <a class="card-header btn" @click="navigate" @keydown.enter="navigate as any" tabindex="0" role="link">
                    <div class="card-title title">{{ song.title }}</div>
                    <song-sync-status class="song-sync-status" :song="song"></song-sync-status>
                </a>
            </router-link>
            <router-link :to="{ name: 'song', query: { song: song.id } }" custom v-slot="{ navigate }">
                <a
                    class="card-body"
                    @click="navigate"
                    role="link"
                    :aria-label="$t('actionView')"
                    :title="$t('actionView')">
                    <preview class="preview" :song="song"></preview>
                </a>
            </router-link>
            <div class="card-footer d-flex justify-content-between">
                <span class="icon song-visibility">
                    <i
                        v-if="song.metadata.visibility === 'public'"
                        class="bi bi-people-fill"
                        :title="$t('visibilityPublic')"></i>
                </span>
                <song-actions :song="song"></song-actions>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Preview from "../../preview/preview.vue";
import SongActions from "@components/song-actions/song-actions.vue";
import SongSyncStatus from "@components/song-sync-status/song-sync-status.vue";
import { Song } from "../../../models/song/song";

export default defineComponent({
    name: "song-preview-item",
    props: {
        song: {
            type: Song,
            required: true,
        },
    },
    components: {
        preview: Preview,
        "song-actions": SongActions,
        "song-sync-status": SongSyncStatus,
    },
});
</script>

<style lang="scss" scoped>
.song {
    .card {
        transition: box-shadow 0.2s ease-in-out;
        border: solid 1px var(--gray-100);
        border-radius: 6px;

        &:hover {
            box-shadow: var(--shadow) !important;

            .card-header,
            .card-body {
                cursor: pointer;
            }
        }

        &.coloured {
            padding-left: 5px;
            border-left-color: transparent;

            .card-header {
                border-radius: 0 4px 0 0;
            }

            .card-footer {
                border-radius: 0 0 4px 0;
                overflow: hidden;
            }
        }

        .card-header {
            background-color: var(--background-color-secondary);
            border: none;
            border-radius: 4px 4px 0 0;
            display: flex;
            justify-content: center;
            padding: 9px 10px;
            font-size: 14px;
            min-height: 39px;

            .card-title {
                font-weight: bold;
                margin-bottom: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                user-select: none;
                white-space: nowrap;
            }

            .song-sync-status {
                position: absolute;
                top: 0;
                right: 5px;
            }
        }

        .card-body {
            padding: 0;
        }

        .card-footer {
            background-color: var(--gray-50);
            border-top-color: var(--gray-200);
            padding: 2px 5px;
        }
    }
}
</style>
