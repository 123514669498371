<template>
    <div class="sync-status d-flex gap-2">
        <i
            v-if="showIcon"
            class="sync-status-icon bi"
            :class="{ promoted: onlyShowPromoted, [syncImage]: true, error: isErrorStatus }"
            :title="syncText"></i>
        <span v-if="showText" class="sync-status-text">{{ syncText }}</span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SyncStatus } from "../../../common/constants";

export default defineComponent({
    name: "sync-status",
    props: {
        showText: {
            type: Boolean,
            default: false,
        },
        onlyShowPromoted: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        syncStatus(): SyncStatus {
            return this.$store.song.syncStatus;
        },
        isErrorStatus(): boolean {
            return this.syncStatus === SyncStatus.ERROR;
        },
        isPromotedStatus(): boolean {
            return [SyncStatus.IN_PROGRESS, SyncStatus.OFFLINE, SyncStatus.ERROR].includes(this.syncStatus);
        },
        showIcon(): boolean {
            return !this.onlyShowPromoted || this.isPromotedStatus;
        },
        syncText(): string {
            switch (this.syncStatus) {
                case SyncStatus.OFFLINE:
                    return this.$t("syncStatusOffline");
                case SyncStatus.LOGGED_OUT:
                    return this.$t("syncStatusLoggedOut");
                case SyncStatus.IN_PROGRESS:
                    return this.$t("syncStatusInProgress");
                case SyncStatus.DONE:
                    return this.$t("syncStatusDone");
                case SyncStatus.ERROR:
                    return this.$t("syncStatusError");
                case SyncStatus.NOT_SYNCED:
                    return this.$t("syncStatusNotSynced");
                default:
                    return this.$t("syncStatusUnknown");
            }
        },
        syncImage(): string {
            switch (this.syncStatus) {
                case SyncStatus.OFFLINE:
                    return "bi-cloud-slash";
                case SyncStatus.LOGGED_OUT:
                    return "bi-person-fill-x";
                case SyncStatus.IN_PROGRESS:
                    return "bi-arrow-repeat";
                case SyncStatus.DONE:
                    return "bi-cloud-check";
                case SyncStatus.ERROR:
                    return "bi-exclamation-triangle-fill";
                case SyncStatus.NOT_SYNCED:
                    return "bi-person-fill-x";
                default:
                    return "bi-file-earmark-x";
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.sync-status {
    align-items: center;
    white-space: normal;

    .sync-status-icon {
        &.error {
            color: var(--warning-color-dark);
        }

        &.promoted {
            background-color: var(--bs-light);
            border-radius: 50%;
            height: 22px;
            line-height: 20px;
            padding-left: 1px;
            position: absolute;
            right: 10px;
            text-align: center;
            top: 10px;
            width: 22px;
            overflow: hidden;

            &.error {
                background-color: transparent;
                color: var(--warning-color);
                font-size: 18px;
                z-index: 0;
                top: 12px;
                right: 12px;

                &::after {
                    content: "";
                    width: 3px;
                    height: 8px;
                    background-color: #ffffff;
                    position: absolute;
                    top: 8px;
                    left: 10px;
                    z-index: -1;
                }
            }
        }
    }
    .sync-status-text {
        text-align: left;
        font-size: 11px;
    }
}
</style>
