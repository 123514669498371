export interface SongMetadata {
    lastEditTime: number;
    createdAt: number;
    visibility: SongVisibility;
    labels?: Array<string>;
}

export type SongVisibility = "private" | "public";

export const DEFAULT_VISIBILITY = "private" as const;
