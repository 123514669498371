<template>
    <div>
        <a
            class="btn user-dropdown-menu dropdown-toggle"
            :class="{
                'btn-hover-gray-light': useLightStyle,
            }"
            href="#"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            :aria-label="$t('toggleUserMenu')"
            :title="$t('toggleUserMenu')">
            <div v-if="isLoggedIn">
                <i v-if="avatarUrl === ''" class="bi bi-person-circle"></i>
                <div v-else class="avatar-container">
                    <i v-if="isOffline" class="bi bi-person-circle"></i>
                    <img v-else class="avatar-image" :src="avatarUrl" alt="User Avatar" />
                    <div class="promoted-sync-status">
                        <sync-status :onlyShowPromoted="true"></sync-status>
                    </div>
                </div>
            </div>
            <i v-else class="bi bi-cloud-slash-fill"></i
        ></a>
        <ul class="dropdown-menu dropdown-menu-end" data-bs-popper="static">
            <template v-if="isLoggedIn">
                <li>
                    <div class="dropdown-header email d-flex gap-2" :title="user?.email ?? undefined">
                        <i class="bi bi-envelope-at"></i><span class="user-email">{{ user?.email }}</span>
                    </div>
                    <div class="dropdown-header sync-status">
                        <sync-status :showText="true"></sync-status>
                    </div>
                </li>
                <li>
                    <a class="btn" :class="{ 'btn-hover-gray-light': useLightStyle }" @click="logout"
                        ><i class="bi bi-box-arrow-left"></i> {{ $t("logOut") }}</a
                    >
                </li>
            </template>
            <template v-else>
                <li>
                    <a
                        class="btn login-button gap-1"
                        :class="{ 'btn-hover-gray-light': useLightStyle }"
                        @click="login"
                        @keydown.enter="login"
                        tabindex="0">
                        <div>
                            <i class="bi bi-person-circle"></i>
                            {{ $t("logIn") }}
                        </div>
                        <div class="login-features">
                            {{ $t("syncStatusLoggedOut") }}
                        </div>
                    </a>
                </li>
            </template>
            <hr class="dropdown-divider" />

            <li class="accordion accordion-flush" id="communityAccordion">
                <div class="accordion-item">
                    <div class="accordion-header" id="headingOne">
                        <button
                            class="btn accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne">
                            <i class="bi bi-chat-heart"></i> {{ $t("linkCommunity") }}
                        </button>
                    </div>
                    <div
                        id="collapseOne"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#communityAccordion">
                        <ul class="dropdown-submenu">
                            <li>
                                <a class="btn" href="https://my-guitar-tabs.com/discord">
                                    <i class="bi bi-discord"></i>{{ $t("linkDiscord") }}
                                </a>
                            </li>
                            <li>
                                <a class="btn" href="https://www.reddit.com/r/myguitartabs/">
                                    <i class="bi bi-reddit"></i>{{ $t("linkReddit") }}
                                </a>
                            </li>
                            <li>
                                <a class="btn" href="https://www.instagram.com/myguitartabs/">
                                    <i class="bi bi-instagram"></i>{{ $t("linkInstagram") }}
                                </a>
                            </li>
                            <li>
                                <a class="btn" href="https://my-guitar-tabs.com/contact"
                                    ><i class="bi bi-chat-dots-fill"></i> {{ $t("linkContactUs") }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </li>

            <li v-if="showAndroidRatingLink">
                <a class="btn" href="https://play.google.com/store/apps/details?id=com.my_guitar_tabs">
                    <i class="bi bi-star"></i>{{ $t("linkRateApp") }}
                </a>
            </li>
            <li v-if="showiOSRatingLink">
                <a class="btn" href="https://itunes.apple.com/us/app/appName/id6560117297?mt=8&action=write-review">
                    <i class="bi bi-star"></i>{{ $t("linkRateApp") }}
                </a>
            </li>
            <li>
                <a class="btn" href="https://my-guitar-tabs.com/docs">
                    <i class="bi bi-question-circle"></i>{{ $t("linkManual") }}
                </a>
            </li>
            <li>
                <router-link class="btn" :to="{ name: 'settings' }"
                    ><i class="bi bi-gear"></i> {{ $t("linkSettings") }}</router-link
                >
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { User } from "firebase/auth";
import { defineComponent } from "vue";
import { isOffline } from "../../utils/is-offline";
import { api } from "../../apis/api";
import SyncStatus from "../sync-status/sync-status.vue";

export default defineComponent({
    name: "user-dropdown-menu",
    props: {
        useLightStyle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            securedData: "",
        };
    },
    components: {
        "sync-status": SyncStatus,
    },
    computed: {
        user(): User | undefined {
            return this.$store.user.user;
        },
        isLoggedIn(): boolean {
            return this.$store.user.loggedIn;
        },
        avatarUrl(): string {
            return this.$store.user.user?.providerData[0]?.photoURL ?? "";
        },
        isOffline(): boolean {
            return isOffline();
        },
        showAndroidRatingLink(): boolean {
            return this.$store.config.appVersion === "android";
        },
        showiOSRatingLink(): boolean {
            return api.auth.isIOSApp;
        },
    },
    methods: {
        async logout(): Promise<void> {
            await this.$store.user.logout();
            await this.$router.push({ name: "dashboard" });
        },
        async login(): Promise<void> {
            await this.$router.push({
                name: "signin",
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.user-dropdown-menu {
    &.show {
        background-color: var(--gray-200);
    }

    .avatar-container {
        position: relative;
        width: 16px;

        .avatar-image {
            width: 32px;
            height: 32px;
            margin: 0 -8px;
            border-radius: 50%;
        }
    }
}

.dropdown-menu {
    min-width: 220px;
    max-width: 290px;

    .login-button {
        display: flex;
        flex-direction: column;
        height: auto;

        .login-features {
            font-size: 11px;
            white-space: normal;
        }
    }

    .dropdown-header {
        cursor: default;
        padding-bottom: 0;

        .user-email {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .dropdown-submenu {
        list-style: none;
        padding-left: 0;
        background-color: #fafafa;

        li {
            a {
                padding-left: 32px;
            }
        }
    }
}
</style>
