import type { TablatureEvent, BarLineType } from "../../common/tablature-event";

export function countBarLineEvents(
    events: Array<TablatureEvent | undefined>,
    maxEventIndex: number,
    barlineType: BarLineType
): number {
    let repeatCount = 0;
    for (let i = 0; i < maxEventIndex; i++) {
        if (events[i]?.data === barlineType) {
            repeatCount++;
        }
    }
    return repeatCount;
}
