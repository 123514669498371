<template>
    <template v-if="hasSearch">
        <div class="songs-summary">
            <div class="notification search-result">
                <i class="bi bi-search"></i>
                <span v-if="hasResults">{{ $t("searchMatches", { count: resultsCount }) }}</span>
                <span v-else>{{ $t("noSongsMatchSearch") }}</span>
            </div>
        </div>
    </template>

    <template v-if="!hasSearch && !hasResults">
        <div class="songs-summary">
            <div class="notification no-songs">
                <i class="bi bi-music-note"></i>
                <span v-if="isSyncing">{{ $t("syncInitialSongsLoad") }}</span>
                <span v-else>{{ $t("noSongs") }}</span>
            </div>
        </div>
    </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SyncStatus } from "../../../../common/constants";

export default defineComponent({
    name: "songs-summary",
    props: {
        searchValue: {
            type: String,
            default: "",
        },
        resultsCount: {
            type: Number,
            default: 0,
        },
        showCreateSong: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasResults(): boolean {
            return this.resultsCount > 0;
        },
        hasSearch(): boolean {
            return this.searchValue !== "";
        },
        syncStatus(): SyncStatus {
            return this.$store.song.syncStatus;
        },
        isSyncing(): boolean {
            return this.syncStatus === SyncStatus.IN_PROGRESS;
        },
    },
});
</script>

<style lang="scss" scoped>
.songs-summary {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 30px;
    min-height: 34px;

    .notification {
        align-items: center;
        color: var(--text-color-light);
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 5px 10px;
        justify-content: center;
    }
}
</style>
