export interface TablaturePosition {
    eventIndex: number;
    stringIndex: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTablaturePosition(object: any): object is TablaturePosition {
    return object !== undefined && "eventIndex" in object && "stringIndex" in object;
}

export function isSamePosition(pos1: TablaturePosition, pos2: TablaturePosition): boolean {
    return pos1.eventIndex === pos2.eventIndex && pos1.stringIndex == pos2.stringIndex;
}
