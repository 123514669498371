import type { TablatureEditor } from "../../../song-editor/tablature-editor";
import type { EditorSelection } from "../../../editor-selection/editor-selection";

export function deleteSelectionRange(TablatureEditor: TablatureEditor, editorSelection: EditorSelection): boolean {
    const rangeSelection = editorSelection.range;
    if (!rangeSelection) {
        return false;
    }

    TablatureEditor.deleteRange(rangeSelection);
    TablatureEditor.insertEventsAfter(rangeSelection.from.eventIndex - 1, undefined);
    editorSelection.navigate.top();
    return true;
}
