import type { Fret } from "../../../../../common/note";
import { getEditorStore } from "../../../../stores/editor.store";
import { sanitizeFret } from "../../../../utils/utils";
import { EditorCommand } from "../editor-command";
import { deleteSelectionRange } from "../utils/delete-selection-range";

export class InsertNoteCommand extends EditorCommand {
    private fret: Fret | undefined;

    constructor({ fret }: { fret: string }) {
        super();
        this.fret = sanitizeFret(fret);
    }

    public onExecute(): void {
        if (!this.editorSelection.getPosition()) return;

        deleteSelectionRange(this.tablatureEditor, this.editorSelection);
        const selectedPosition = this.editorSelection.getPosition();
        if (!selectedPosition) throw new Error("[InsertNoteCommand] Error after deselecting range");

        this.tablatureEditor.updateNoteFret(selectedPosition, this.fret);

        if (this.isAtLastString()) {
            this.tablatureEditor.insertEventsAfter(selectedPosition.eventIndex, undefined);
        }
        this.editorSelection.cursor.next();

        const editorStore = getEditorStore();
        editorStore.showSuggestions = true;
    }

    private isAtLastString(): boolean {
        const selectionPosition = this.editorSelection.getPosition();
        if (!selectionPosition) {
            return false;
        }
        return selectionPosition.stringIndex === this.tablature.lineCount - 1;
    }
}
