<template></template>

<script lang="ts">
import { defineComponent } from "vue";
import Swal from "sweetalert2";

export default defineComponent({
    name: "toast",
    data() {
        return {
            errorToast: Swal.mixin({
                toast: true,
                position: "top",
                showConfirmButton: false,
                timer: 1600,
                icon: "error",
                showClass: {
                    popup: "fade-in-animation",
                },
                hideClass: {
                    popup: "fade-out-animation",
                },
            }),
            infoToast: Swal.mixin({
                toast: true,
                position: "bottom",
                showConfirmButton: false,
                timer: 1600,
                icon: "info",
                showClass: {
                    popup: "fade-in-animation",
                },
                hideClass: {
                    popup: "fade-out-animation",
                },
            }),
        };
    },

    mounted() {
        this.$bus.on("error", (msg: string) => {
            this.showErrorToast(msg);
        });
        this.$bus.on("info", (msg: string) => {
            this.showInfoToast(msg);
        });
    },
    methods: {
        showErrorToast(msg: string): void {
            console.error(msg);
            this.errorToast.fire({
                text: msg,
            });
        },
        showInfoToast(msg: string): void {
            this.infoToast.fire({
                text: msg,
            });
        },
    },
});
</script>
