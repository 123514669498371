import { CopyCommand } from "./copy";

export class CutCommand extends CopyCommand {
    public get modifiesSong(): boolean {
        return true;
    }

    public async onExecute(): Promise<void> {
        const copied = await this.copyToClipboard();
        if (!copied) return;
        const rangeSelection = this.editorSelection.range;
        if (!rangeSelection) return;
        this.tablatureEditor.deleteRange(rangeSelection);
        this.editorSelection.clearRange();
    }
}
