<template>
    <app-modal @close="onClose" :show-close-button="false" :close-on-backdrop-click="false">
        <template v-slot:header>{{ $t("songSettings") }}</template>
        <template v-slot:body>
            <div class="song-settings-modal-container">
                <div class="song-settings-section row g-3">
                    <div class="form-group col-12 mb-3">
                        <label for="instrumentSelector" class="form-label">{{ $t("instrumentSelection") }}:</label>
                        <select
                            class="form-select form-select-lg"
                            id="instrumentSelector"
                            tabindex="0"
                            v-model="instrument">
                            <option v-for="instrument of songInstruments" :value="instrument">
                                {{ $t(instrument) }}
                            </option>
                        </select>

                        <p v-if="instrumentChanged">{{ $t("instrumentWarning") }}</p>
                    </div>
                    <div class="form-group col-12 mb-3">
                        <label for="songArtist" class="form-label">{{ $t("songSettingsArtist") }}:</label>
                        <input
                            id="songArtist"
                            class="form-control"
                            type="text"
                            tabindex="0"
                            v-model="artist"
                            :maxlength="maxStringLength" />
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button class="btn btn-secondary" @click="onClose">{{ $t("done") }}</button>
        </template>
        ></app-modal
    >
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Song } from "../../../models/song/song";
import AppModal from "@components/app-modal/app-modal.vue";
import { MAX_STRING_LENGTH, SONG_INSTRUMENTS, SongInstrument } from "../../../../common/constants";

export default defineComponent({
    name: "song-settings-modal",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    data() {
        return {
            artist: this.song.tags.artist,
            instrument: (this.song.tags.instrument || "guitar") as SongInstrument,
            songInstruments: SONG_INSTRUMENTS,
        };
    },

    emits: {
        close: () => true,
    },
    components: {
        "app-modal": AppModal,
    },
    computed: {
        songChanged(): boolean {
            return this.artist !== this.song.tags.artist || this.instrumentChanged;
        },
        instrumentChanged(): boolean {
            return this.instrument !== this.song.tags.instrument;
        },
        maxStringLength(): number {
            return MAX_STRING_LENGTH;
        },
    },
    methods: {
        onClose(): void {
            if (this.songChanged) {
                this.updateSong();
            }

            this.$emit("close");
        },
        updateSong(): void {
            this.song.tags.artist = this.artist;
            if (this.instrumentChanged) {
                this.song.updateInstrument(this.instrument);
                this.$store.editor.selection.setSongStrings(this.song.tablature.lineCount);
            }
            this.markSongAsChanged();
        },
        markSongAsChanged(): void {
            this.$store.editor.markAsChanged();
        },
    },
});
</script>
