import type { Fret } from "../../common/note";
import type { Point } from "../models/point";

/** Filter all elements in an array, only leaving truthy values */
export function filterTruthy(arr: (boolean | null | undefined)[]): true[];
export function filterTruthy<T>(arr: (T | null | undefined)[]): T[];
export function filterTruthy<T>(arr: (T | null | undefined)[]) {
    return arr.filter(Boolean);
}

export function clamp(val: number, min?: number, max?: number): number {
    if (min !== undefined) {
        val = Math.max(min, val);
    }
    if (max !== undefined) {
        val = Math.min(max, val);
    }
    return val;
}

export function pointToTransform(point: Point): string {
    return `translate(${point.x}, ${point.y})`;
}

export function sanitizeFret(value: unknown): Fret | undefined {
    if (value === "X" || value === "x") return "x";
    if (value === undefined) return undefined;

    const asNumber = Number(value);
    if (!Number.isNaN(asNumber)) {
        return asNumber; // TODO: validate numbers
    } else throw new Error(`Invalid Fret value ${value}`);
}

// Wraps a number around between min and max, only 1 overflow number supported
export function wrapNumber(n: number, min: number, max: number): number {
    if (n > max) return min;
    if (n < min) return max;
    return n;
}

/** Ensures input is an array, if not, turns it into an array (empty array if input is null or undefined) */
export function arrayfy<T>(raw: T | Array<T> | undefined | null): Array<T> {
    if (Array.isArray(raw)) return raw;
    if (raw === undefined || raw === null) return [];
    else return [raw];
}

/** Check if something is a number */
export function isNumber(n: unknown): n is number {
    return typeof n === "number" && !Number.isNaN(n);
}

export function modulo(number: number, module: number) {
    return ((number % module) + module) % module;
}

/** Omits fields from record */
export function omitFields<T>(x: Record<string, T>, fields: string[]): Record<string, T> {
    return Object.entries(x)
        .filter((item) => !fields.includes(item[0]))
        .reduce((acc: Record<string, T>, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});
}

export function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

/** Defines a type representing the value of an object **/
export type ValueOf<T> = T[keyof T];

export function isWhitespaceString(text: string): boolean {
    return !text.replace(/\s/g, "").length;
}

/** Checks if element is in array, and checks its type */
export function isInArray<T>(arr: Array<T> | ReadonlyArray<T>, element: unknown): element is T {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return arr.includes(element as any);
}
