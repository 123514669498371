import { EditorCommand } from "../editor-command";

export class SetEventAnnotation extends EditorCommand {
    private eventIndex: number;
    private annotation: string | undefined;

    constructor({ eventIndex, annotation }: { eventIndex: number; annotation: string | undefined }) {
        super();
        this.eventIndex = eventIndex;
        this.annotation = annotation;
    }

    public onExecute(): void {
        this.tablatureEditor.setEventAnnotation(this.eventIndex, this.annotation);
    }
}
