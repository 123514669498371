import type { TablaturePosition } from "../../interfaces/tablature-position";
import { modulo } from "../../utils/utils";
import type { EditorSelection } from "./editor-selection";

export class EditorCursor {
    private editorSelection: EditorSelection;

    constructor(selection: EditorSelection) {
        this.editorSelection = selection;
    }

    public next(): void {
        this.cursorMove(1);
    }

    public previous(): void {
        this.cursorMove(-1);
    }

    public nextChord(chordCount = 1): void {
        const position = this.editorSelection.getPosition();
        if (!position) {
            throw new Error("Cannot perform cursor move without a selection");
        }
        position.eventIndex += chordCount;
        position.stringIndex = 0;

        this.editorSelection.set(position);
    }

    public previousChord(chordCount = 1): void {
        const position = this.editorSelection.getPosition();
        if (!position) {
            throw new Error("Cannot perform cursor move without a selection");
        }
        position.eventIndex = Math.max(position.eventIndex - chordCount, 0);
        position.stringIndex = 0;

        this.editorSelection.set(position);
    }

    private get stringCount(): number {
        return this.editorSelection.stringCount;
    }

    private cursorMove(amount: number): TablaturePosition {
        const position = this.editorSelection.getPosition();
        if (!position) {
            throw new Error("Cannot perform cursor move without a selection");
        }
        if (amount === 0) {
            throw new Error("Cannot move cursor by 0");
        }

        let newStringIndex = modulo(position.stringIndex + amount, this.stringCount);
        const chordOffset = Math.floor((position.stringIndex + amount) / this.stringCount);

        // Apply chord jumps
        let newEventIndex = position.eventIndex + chordOffset;
        if (newEventIndex < 0) {
            newEventIndex = 0;
            newStringIndex = 0;
        }
        position.eventIndex = newEventIndex;
        position.stringIndex = newStringIndex;
        this.editorSelection.set(position);
        return position;
    }
}
