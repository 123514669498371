<template>
    <line x1="-2" y1="0" x2="-2" :y2="barHeight" />
    <line x1="2" y1="0" x2="2" :y2="barHeight" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Geometry } from "../../../services/renderer/geometry";

export default defineComponent({
    name: "double-bar-line",
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
    },
    computed: {
        barHeight(): number {
            return this.geometry.getRowHeight();
        },
    },
});
</script>

<style lang="scss" scoped>
line {
    stroke: var(--text-color);
    stroke-width: 1;
}
</style>
