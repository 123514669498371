<template>
    <div class="text-input-container">
        <span
            ref="textInput"
            class="text-input tablature-text"
            :class="{ 'text-input-error': hasExceededMaxLength }"
            :contenteditable="canEdit"
            :title="hasExceededMaxLength ? $t('eventAnnotationMaxLength') : ''"
            @input="onInput"
            @focusout="onUnfocus"
            @focus="onFocus"
            @keydown="onKeypress">
            {{ annotation }}
        </span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Point } from "../../../models/point";
import { Geometry } from "../../../services/renderer/geometry";
import { MAX_STRING_LENGTH } from "../../../../common/constants";

export default defineComponent({
    name: "event-annotation-editor",
    props: {
        eventIndex: {
            type: Number,
            required: true,
        },
        geometry: {
            type: Geometry,
            required: true,
        },
        annotation: {
            type: String,
            required: true,
        },
        canEdit: {
            type: Boolean,
            required: true,
        },
    },
    emits: {
        updateAnnotation: (_annotation: string | undefined) => true,
    },
    data() {
        const annotationPadding = this.geometry.style.annotationPadding;

        return {
            padding: new Point(0, -annotationPadding),
            value: this.annotation,
            hasExceededMaxLength: false,
        };
    },
    watch: {
        annotation: {
            handler(newValue) {
                this.value = newValue;
            },
            immediate: true,
        },
    },
    computed: {
        paddedPosition(): Point {
            return this.textPoint.plus(this.padding);
        },
        positionTop(): string {
            return `${this.paddedPosition.y}px`;
        },
        positionLeft(): string {
            return `${this.paddedPosition.x}px`;
        },
        textPoint(): Point {
            return this.geometry.getEventPoint(this.eventIndex);
        },
        stringLabelCursor(): "text" | "auto" {
            return this.canEdit ? "text" : "auto";
        },
        userSelect(): "text" | "none" {
            return this.canEdit ? "text" : "none";
        },
    },
    methods: {
        onKeypress(ev: KeyboardEvent): void {
            // Need to check ev.key for mobile
            if (ev.code === "Enter" || ev.key === "Enter") {
                ev.preventDefault();
                this.blur();
            }

            // Prevents unwanted horizontal scrolling in the editor when the browser auto-scrolls
            // to keep an input visible. Resets scrollLeft to 0 after rendering to ensure proper alignment.
            const editorContainer = document.getElementById("tablature-editor");
            if (editorContainer && editorContainer.scrollLeft !== 0) {
                setTimeout(() => {
                    editorContainer.scrollLeft = 0;
                });
            }
        },
        onFocus() {
            this.$store.editor.clearSelection();
        },
        onInput(e: any) {
            const trimmedValue = e.target.innerText.trim();
            this.hasExceededMaxLength = trimmedValue.length > MAX_STRING_LENGTH;
        },
        onUnfocus(e: any) {
            const trimmedValue = e.target.innerText.trim();
            this.value = trimmedValue.slice(0, MAX_STRING_LENGTH);
            this.hasExceededMaxLength = false;

            // Reflects the sliced value back into the input
            e.target.innerText = this.value;

            this.$emit("updateAnnotation", this.value || undefined);
        },
        // Used externally
        focus() {
            const input = this.$refs.textInput as HTMLInputElement;
            input.focus();
        },
        blur() {
            const input = this.$refs.textInput as HTMLInputElement;
            input.blur();
        },
    },
});
</script>

<style lang="scss" scoped>
.text-input-container {
    cursor: v-bind("stringLabelCursor");
    height: 1px;
    left: v-bind("positionLeft");
    position: absolute;
    top: v-bind("positionTop");
    user-select: v-bind("userSelect");
    width: 1px;
}

.text-input {
    background: none;
    border: none;
    outline: none;
    cursor: v-bind("stringLabelCursor");
    display: inline-block;
    transform: translate(-50%, -50%) scale(0.85);
    padding: 0 6px;
    font-size: 16px;
    user-select: v-bind("userSelect");
    vertical-align: top;
    white-space: nowrap;

    &:focus {
        background-color: var(--background-color-secondary);
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }

    &:not(:focus) {
        background: none;
    }

    &.text-input-error {
        color: red;
    }
}
</style>
