<template>
    <g :transform="transform">
        <line :x1="paddingX" y1="-4" :x2="paddingX" :y2="topDiff + 2" stroke="black" stroke-width="1" />
        <path class="arrow" stroke-width="0.2" :d="arrowPath"></path>
        <text class="tablature-text" :x="paddingX" :y="textPosition" text-anchor="middle">{{ bendText }}</text>
    </g>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { NoteModifier, PreBendModifier } from "../../../../../common/note";
import { Point } from "../../../../models/point";

export default defineComponent({
    name: "pre-bend",
    props: {
        from: {
            type: Point,
            required: true,
        },
        bend: {
            type: String as PropType<PreBendModifier | undefined>,
            required: true,
        },
        top: {
            type: Number as PropType<number>,
            required: true,
        },
    },
    computed: {
        paddingX() {
            return -6;
        },
        transform(): string {
            const offset = new Point(6, -4);
            const originPoint = this.from.plus(offset);
            return `translate(${originPoint.x}, ${originPoint.y})`;
        },
        bendText(): string {
            if (this.bend === NoteModifier.halfPreBend) return "1/2";
            if (this.bend === NoteModifier.preBend) return "1";
            if (this.bend === NoteModifier.quarterPreBend) return "1/4";
            if (this.bend === NoteModifier.doublePreBend) return "2";
            else return "";
        },
        topDiff(): number {
            return this.top - this.from.y - 12;
        },
        arrowPath(): string {
            const top = this.topDiff;
            const arrowBase = top + 4;
            return `M-6 ${top} L-10 ${arrowBase} L-2 ${arrowBase}Z`;
        },
        textPosition(): number {
            return this.topDiff - 7;
        },
    },
});
</script>

<style lang="scss" scoped>
path {
    stroke: var(--text-color);
    &.arrow {
        fill: var(--text-color);
    }
}
</style>
