<template>
    <div class="song-sync-status d-flex gap-2">
        <i
            v-if="showSyncStatus"
            class="song-sync-status-icon bi"
            :class="{ [syncImage]: true, error: isErrorStatus }"
            :title="syncText"></i>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SyncStatus } from "../../../common/constants";
import { Song } from "../../models/song/song";
import { api } from "../../apis/api";
import { SongTransactionLog, SongTransactionLogRecord } from "../../apis/song-sync";

export default defineComponent({
    name: "song-sync-status",
    props: {
        song: {
            type: Song,
            required: true,
        },
    },
    computed: {
        songChangelogRecord(): SongTransactionLogRecord | undefined {
            return this.getChangelog()[this.song.id];
        },
        hasPendingChanges(): boolean {
            return !!this.songChangelogRecord;
        },
        showSyncStatus(): boolean {
            return this.isLoggedIn && this.hasPendingChanges;
        },
        isLoggedIn(): boolean {
            return this.$store.user.loggedIn;
        },
        syncStatus(): SyncStatus {
            return this.$store.song.syncStatus;
        },
        isErrorStatus(): boolean {
            return this.syncStatus === SyncStatus.ERROR;
        },
        syncText(): string {
            switch (this.syncStatus) {
                case SyncStatus.OFFLINE:
                    return this.$t("syncStatusOffline");
                case SyncStatus.LOGGED_OUT:
                    return this.$t("syncStatusLoggedOut");
                case SyncStatus.IN_PROGRESS:
                    return this.$t("syncStatusInProgress");
                case SyncStatus.DONE:
                    return this.$t("syncStatusDone");
                case SyncStatus.ERROR:
                    return this.$t("syncStatusError");
                case SyncStatus.NOT_SYNCED:
                    return this.$t("syncStatusNotSynced");
                default:
                    return this.$t("syncStatusUnknown");
            }
        },
        syncImage(): string {
            switch (this.syncStatus) {
                case SyncStatus.OFFLINE:
                    return "bi-cloud-slash";
                case SyncStatus.LOGGED_OUT:
                    return "bi-person-fill-x";
                case SyncStatus.IN_PROGRESS:
                    return "bi-arrow-repeat";
                case SyncStatus.DONE:
                    return "bi-cloud-check";
                case SyncStatus.ERROR:
                    return "bi-exclamation-triangle-fill";
                case SyncStatus.NOT_SYNCED:
                    return "bi-person-fill-x";
                default:
                    return "bi-file-earmark-x";
            }
        },
    },
    methods: {
        getChangelog(): SongTransactionLog {
            return api.localStorage.getObject<SongTransactionLog>("songTransactionLog") || {};
        },
    },
});
</script>

<style lang="scss" scoped>
.song-sync-status {
    align-items: center;
    white-space: normal;

    .song-sync-status-icon {
        &.error {
            color: var(--warning-color);
        }
    }
}
</style>
