import type { RouteLocationNormalized, Router } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import Swal from "sweetalert2";

import { getConfigStore } from "../stores/config.store";
import { getEditorStore } from "../stores/editor.store";
import { getSongStore } from "../stores/song.store";
import { getUserStore } from "../stores/user.store";

import Archive from "../pages/archive/archive.vue";
import Dashboard from "../pages/dashboard/dashboard.vue";
import EditorLoader from "../pages/editor/editor-loader.vue";
import ShareLoader from "../pages/share/share-loader.vue";
import Settings from "../pages/settings/settings.vue";
import ConfirmEmail from "../pages/signin/confirm-email.vue";
import Signin from "../pages/signin/signin.vue";
import Trash from "../pages/trash/trash.vue";
import ViewerLoader from "../pages/viewer/viewer-loader.vue";
import { api } from "../apis/api";
import { SyncStatus } from "../../common/constants";

let routerMemo: Router | undefined = undefined;

/**
 * The options for Router Meta, please update vue-router.d.ts if needed
 */

export function getRouter(): Router {
    if (routerMemo) return routerMemo;
    const userStore = getUserStore();
    const songStore = getSongStore();

    const router = createRouter({
        history: createWebHistory(),
        routes: [
            {
                name: "dashboard",
                path: "/",
                component: Dashboard,
                meta: { fetchSongs: true, syncRemote: true },
            },
            {
                name: "notebook",
                path: "/notebook/:notebook",
                component: Dashboard,
                meta: { fetchSongs: true, syncRemote: true },
            },
            { name: "signin", path: "/signin", component: Signin, meta: { redirectIfLoggedIn: true } },
            { name: "song", path: "/song", component: ViewerLoader, meta: { wakeLock: true } },
            { name: "share", path: "/share", component: ShareLoader, meta: { wakeLock: true } },
            { name: "edit", path: "/edit", component: EditorLoader, meta: { wakeLock: true } },
            { name: "create", path: "/create", component: EditorLoader, meta: { wakeLock: true } },
            { name: "archive", path: "/archive", component: Archive, meta: { fetchSongs: true, syncRemote: true } },
            { name: "trash", path: "/trash", component: Trash, meta: { fetchSongs: true, syncRemote: true } },
            {
                name: "settings",
                path: "/settings",
                component: Settings,
                meta: {
                    fetchSongs: true,
                },
            },
            {
                name: "confirm-email",
                path: "/confirm-email",
                component: ConfirmEmail,
                meta: { redirectIfLoggedIn: true },
            },
            { name: "default", path: "/:catchAll(.*)", redirect: "/" },
        ],
    });

    router.beforeEach(async (to: RouteLocationNormalized, _from: RouteLocationNormalized) => {
        await userStore.refreshCurrentUser();
        cleanUpStateBetweenPages();
        if (userStore.loggedIn) {
            if (to.meta.redirectIfLoggedIn) {
                return { name: "dashboard" };
            }
        }

        if (to.meta.syncRemote && songStore.syncStatus !== SyncStatus.IN_PROGRESS) {
            remoteSync();
        }

        return true;
    });

    router.afterEach(async (to: RouteLocationNormalized) => {
        // Waits 1 ms to wakeLock to ensure page is visible
        setTimeout(() => {
            wakeLockScreenIfNeeded(to);
        });

        if (to.meta.fetchSongs) {
            await songStore.fetchSongs();
        }
    });
    routerMemo = router;
    return router;
}

async function wakeLockScreenIfNeeded(to: RouteLocationNormalized): Promise<void> {
    const configStore = getConfigStore();
    if (to.meta.wakeLock) {
        await configStore.wakeLockScreen();
    } else {
        await configStore.releaseWakeLockScreen();
    }
}

function cleanUpStateBetweenPages(): void {
    clearSelection();
    hideBackdrop();
    hideSwal();
}

function clearSelection(): void {
    const editorStore = getEditorStore();
    editorStore.selection.clear();
}

function hideBackdrop(): void {
    const offcanvasElement = document.getElementsByClassName("offcanvas-backdrop")[0];

    if (offcanvasElement) {
        offcanvasElement.remove();
    }
}

function hideSwal(): void {
    Swal.close();
}

async function remoteSync(): Promise<void> {
    await api.syncApi.sync();
}
