<template>
    <template v-for="(selectedEventIndex, index) of selectedEventIndexes">
        <path
            class="event-selected"
            :class="{ 'selection-in-progress': isSelectionInProgress }"
            :d="getRoundedRectData(index)"
            :transform="selectedTransform(selectedEventIndex)"></path>
    </template>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { roundedRectData } from "../../../utils/svg-rounded-rectangle-path";
import type { TablaturePosition } from "../../../interfaces/tablature-position";
import type { Point } from "../../../models/point";
import { EditorSelection } from "../../../services/editor-selection/editor-selection";
import { Geometry } from "../../../services/renderer/geometry";

export default defineComponent({
    name: "range-selection",
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
    },
    computed: {
        editorSelection(): EditorSelection {
            return this.$store.editor.selection as EditorSelection;
        },
        isSelectionInProgress(): boolean {
            return this.editorSelection.inProgress;
        },
        selectedEventIndexes(): number[] {
            if (!this.$store.editor.selection.hasRange()) {
                return [];
            }

            return this.$store.editor.selection.getSelectedEventIndexes();
        },

        includesFirstSelectedEvent(): boolean {
            const range = this.editorSelection.range;
            if (!range) {
                return false;
            }

            return this.selectedEventIndexes[0] === range.from.eventIndex;
        },
        includesLastSelectedEvent(): boolean {
            const range = this.editorSelection.range;
            if (!range) {
                return false;
            }

            return this.selectedEventIndexes[this.selectedEventIndexes.length - 1] === range.to.eventIndex;
        },
        width(): number {
            return this.geometry.style.eventWidth;
        },
        height(): number {
            return this.lineHeight * (this.geometry.linesCount + 1);
        },
        lineHeight(): number {
            return this.geometry.style.lineHeight;
        },
    },
    methods: {
        getPoint(eventIndex: number, stringIndex = 0): Point {
            const position: TablaturePosition = { eventIndex: eventIndex, stringIndex };
            return this.geometry.tablaturePositionToPoint(position);
        },
        getRoundedRectData(index: number): string {
            return roundedRectData(
                this.width,
                this.height,
                index === 0 && this.includesFirstSelectedEvent ? 8 : 0,
                index === this.selectedEventIndexes.length - 1 && this.includesLastSelectedEvent ? 8 : 0,
                index === this.selectedEventIndexes.length - 1 && this.includesLastSelectedEvent ? 8 : 0,
                index === 0 && this.includesFirstSelectedEvent ? 8 : 0
            );
        },
        selectedTransform(index: number): string {
            const x = this.getPoint(index).x - this.width / 2;
            const y = this.getPoint(index).y - this.lineHeight;
            return `translate(${x}, ${y})`;
        },
    },
});
</script>

<style lang="scss" scoped>
.event-selected {
    visibility: "visible";
    fill: var(--primary-color-200);
    fill-opacity: 0.3;

    &.selection-in-progress {
        fill: var(--primary-color-50);
    }
}
</style>
