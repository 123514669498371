import type { TablatureEvent } from "../../../../../common/tablature-event";
import type { Song } from "../../../../models/song/song";
import { isChordEvent } from "../../../../utils/tablature-events-utils";
import { filterTruthy } from "../../../../utils/utils";
import { EditorCommand } from "../editor-command";

export class DeleteNoteCommand extends EditorCommand {
    private backspace: boolean;

    constructor({ backspace }: { backspace?: boolean } = {}) {
        super();
        this.backspace = backspace === true;
    }

    public onExecute(): void {
        const selectionRange = this.editorSelection.range;
        const selectedPosition = this.editorSelection.getPosition();

        if (selectionRange) {
            this.tablatureEditor.deleteRange(selectionRange);
            this.editorSelection.navigate.top();
        } else if (selectedPosition) {
            this.tablatureEditor.deleteNote(selectedPosition);
        }
        if (this.backspace) {
            this.executeBackspace();
        }
    }

    private executeBackspace() {
        const position = this.editorSelection.getPosition();
        if (!position) throw new Error("No eventIndex in backspace");
        const eventIndex = position.eventIndex;
        const isTopString = position.stringIndex === 0;

        if (this.isEmptyEvent(eventIndex, this.song) && isTopString) {
            this.tablatureEditor.deleteEvent(eventIndex);
        }

        this.editorSelection.cursor.previous();
    }

    private isEmptyEvent(index: number, song: Song): boolean {
        const tablature = song.tablature;
        if (tablature.getEventType(index) === undefined) {
            return true;
        }

        const event = tablature.get(index);
        if (isChordEvent(event)) {
            return this.isEmptyChord(event);
        }
        return false;
    }

    private isEmptyChord(chordEvent: TablatureEvent<"chord">): boolean {
        const chord = chordEvent.data;
        const hasNotes = filterTruthy(chord.notes).length > 0;

        return !hasNotes;
    }
}
