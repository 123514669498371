export interface TablatureStyle {
    eventWidth: number;
    lineHeight: number;
    paddingTop: number;
    paddingRight: number;
    paddingLeft: number;
    paddingBottom: number;
    annotationPadding: number;
}

export const DEFAULT_TABLATURE_STYLE: TablatureStyle = {
    eventWidth: 30,
    lineHeight: 13,
    paddingTop: 65,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 10,
    annotationPadding: 37,
};
