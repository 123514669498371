import type { FirebaseApp } from "firebase/app";
import type { Auth, User, UserCredential } from "firebase/auth";
import { OAuthProvider, getAuth, reauthenticateWithPopup, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { isInArray } from "../utils/utils";
import type { CookieApi } from "./cookies";
import { ApiConfig } from "./config";

export type OauthProviderId = "google.com" | "microsoft.com" | "apple.com";

type LoginResult = {
    user: User;
    isNewUser: boolean;
};

export class AuthApi {
    public auth: Auth;
    private cookies: CookieApi;

    constructor(app: FirebaseApp, cookies: CookieApi) {
        this.auth = getAuth(app);
        this.cookies = cookies;
        // onAuthStateChanged(this.auth, (user) => {
        //     console.log(user);
        // });
    }

    public get isIOSApp(): boolean {
        return this.cookies.get("app-platform") === "iOS App Store";
    }

    public async getCurrentUser(): Promise<User | undefined> {
        await this.auth.authStateReady();
        return this.auth.currentUser || undefined;
    }

    public async getAccessToken(): Promise<string | undefined> {
        await this.auth.authStateReady();
        return this.auth.currentUser?.getIdToken();
    }

    public async deleteAccount(): Promise<void> {
        const user = await this.getCurrentUser();
        if (!user) {
            throw new Error("User not authenticated");
        }
        await user.delete();
    }

    public async reauthenticate(): Promise<LoginResult | void> {
        const user = await this.getCurrentUser();
        if (!user) {
            throw new Error("Cannot reauthenticate, user not logged in");
        }

        const providerId = this.getUserProvider(user);
        if (!providerId) {
            const providersFound = user.providerData.map((provider) => provider.providerId);
            throw new Error(`Cannot reauthenticate, no valid auth provider found. Provider found: ${providersFound}`);
        }

        const provider = new OAuthProvider(providerId);
        try {
            const credentials = await reauthenticateWithPopup(user, provider);
            return this.parseCredential(credentials);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async oauthLogin(providerName: OauthProviderId): Promise<LoginResult | void> {
        const provider = new OAuthProvider(providerName);

        try {
            if (ApiConfig.releaseStage === "development") {
                const credentials = await signInWithPopup(this.auth, provider);
                return this.parseCredential(credentials);
            }
            await signInWithRedirect(this.auth, provider);
            // if (this.isIOSApp ) {
            //     await signInWithRedirect(this.auth, provider);
            // } else {
            //     const credentials = await signInWithPopup(this.auth, provider);

            //     return this.parseCredential(credentials);
            // }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    public async logout(): Promise<void> {
        return this.auth.signOut();
    }

    private parseCredential(credential: UserCredential): LoginResult {
        return {
            user: credential.user,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            isNewUser: Boolean((credential as any)?._tokenResponse?.isNewUser),
        };
    }

    /** Returns first valid found provider */
    private getUserProvider(user: User): OauthProviderId | undefined {
        const providers: OauthProviderId[] = ["google.com", "microsoft.com", "apple.com"];

        return user.providerData
            .filter((provider) => {
                return isInArray(providers, provider.providerId);
            })
            .map((provider) => {
                return provider.providerId as OauthProviderId;
            })[0];
    }
}
