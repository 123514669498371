<template>
    <offcanvas-sidebar></offcanvas-sidebar>
    <sticky-sidebar></sticky-sidebar>
    <div class="layout-content" id="layout-content">
        <slot></slot>
    </div>
    <app-footer :showCreateSong="showCreateSong"></app-footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import OffcanvasSidebar from "@components/sidebar/sidebar-offcanvas.vue";
import StickySidebar from "@components/sidebar/sidebar-sticky.vue";
import AppFooter from "@components/app-footer/app-footer.vue";

export default defineComponent({
    name: "page-wrapper",
    props: {
        showCreateSong: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        "offcanvas-sidebar": OffcanvasSidebar,
        "sticky-sidebar": StickySidebar,
        "app-footer": AppFooter,
    },
});
</script>

<style lang="scss" scoped>
.layout-content {
    height: 100%;
    position: relative;
    overflow: auto;
}

@media (min-width: 1400px) {
    .layout-content {
        height: calc(100% - 62px);
        margin-top: 62px;
        margin-left: 300px;
    }
}
</style>
