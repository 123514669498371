import { SelectionCommand } from "./selection-command";

export class CursorCommand extends SelectionCommand {
    private direction: "next" | "previous";

    constructor({ direction }: { direction: "next" | "previous" }) {
        super();
        this.direction = direction;
    }

    public onExecute(): void {
        switch (this.direction) {
            case "next":
                this.editorSelection.cursor.next();
                break;
            case "previous":
                this.editorSelection.cursor.previous();
                break;
        }
    }
}
