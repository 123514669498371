import type { NoteDTO, NoteModifier } from "./note";

export type EventType = "chord" | "barline" | "newline" | undefined;

export type EventData<T extends EventType> = T extends "chord" ? Chord : T extends "barline" ? BarLineType : undefined;

export interface TablatureEvent<T extends EventType = EventType> {
    type: T;
    annotation?: string;
    data: EventData<T>;
}

export type ChordModifier = "vibrato" | "downstroke" | "upstroke";
export type Modifier = NoteModifier | ChordModifier;

export interface Chord {
    notes: Array<NoteDTO | undefined>; //| ChordDiagramRefDTO;
    modifiers?: ChordModifier[];
}

export enum BarLineType {
    Single = "Single",
    Double = "Double",
    Repeat = "Repeat",
    End = "End",
}
