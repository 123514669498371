import type { Fret, NoteDTO, NoteModifier } from "@common/note";
import type { ChordModifier, TablatureEvent } from "@common/tablature-event";
import type { TablaturePosition } from "../../interfaces/tablature-position";
import type { TablatureRange } from "../../interfaces/tablature-range";
import { isTablatureRange } from "../../interfaces/tablature-range";
import type { Song } from "../../models/song/song";
import type { Tablature } from "../../models/song/tablature";
import { getEditorStore } from "../../stores/editor.store";
import { isChordEvent } from "../../utils/tablature-events-utils";

const INCOMPATIBLE_CHORD_MODIFIERS = {
    vibrato: [],
    downstroke: ["upstroke"],
    upstroke: ["downstroke"],
} as const;

export class TablatureEditor {
    private tablature: Tablature;

    constructor(song: Song) {
        this.tablature = song.tablature;
    }

    public insertEventsAfter(
        index: number,
        events: undefined | TablatureEvent | Array<undefined | TablatureEvent>
    ): void {
        if (!Array.isArray(events)) {
            events = [events];
        }

        this.tablature.events.length = Math.max(this.tablature.events.length, index + 1);
        this.tablature.events.splice(index + 1, 0, ...events);
    }

    public clearEvent(index: number): void {
        this.updateEvent(index, undefined);
    }

    public deleteEvent(index: number): void {
        this.tablature.events.splice(index, 1);
    }

    public deleteNote(position: TablaturePosition): void {
        return this.updateNoteFret(position, undefined);
    }

    public deleteRange(range: TablatureRange | TablaturePosition): void {
        if (!isTablatureRange(range)) {
            return this.deleteNote(range);
        }

        const startEventIndex = range.from.eventIndex;
        const length = range.to.eventIndex - startEventIndex + 1;
        this.tablature.events.splice(startEventIndex, length);
    }

    public updateEvent(index: number, event: TablatureEvent | undefined): void {
        this.tablature.events[index] = event;
    }

    public updateNoteFret(position: TablaturePosition, fret: Fret | undefined): void {
        if (position.stringIndex < 0 || position.stringIndex > this.tablature.stave.lineCount) {
            throw new Error(`Cannot set fret '${fret}' at string '${position.stringIndex}'.`);
        }

        const tablatureEvent = this.tablature.events[position.eventIndex];
        const editorStore = getEditorStore();
        if (!isChordEvent(tablatureEvent)) {
            if (fret === undefined) {
                this.tablature.events[position.eventIndex] = undefined;
            } else {
                const notes: NoteDTO[] = [];
                notes[position.stringIndex] = {
                    fret,
                    modifiers: [],
                };
                const chordEvent: TablatureEvent<"chord"> = {
                    type: "chord",
                    data: { notes },
                };
                this.tablature.events[position.eventIndex] = chordEvent;
                editorStore.lastModifiedNote = {
                    position: { ...position },
                    note: fret,
                };
            }
            return;
        }

        const chord = tablatureEvent.data;

        if (fret === undefined || fret === null) {
            chord.notes[position.stringIndex] = undefined;
            return;
        }

        editorStore.lastModifiedNote = {
            position: { ...position },
            note: fret,
        };
        const modifiers = chord.notes[position.stringIndex]?.modifiers || [];

        const newNote: NoteDTO = {
            fret,
            modifiers,
        };

        const shouldRemoveModifiers = fret === "x";
        if (shouldRemoveModifiers) {
            newNote.modifiers = [];
        }

        chord.notes[position.stringIndex] = newNote;
    }

    public setChordModifier(eventIndex: number, modifier: ChordModifier): void {
        const chord = this.getOrCreateChord(eventIndex);
        const modifiersSet = this.getChordModifiers(chord);
        const incompatibleModifiers = INCOMPATIBLE_CHORD_MODIFIERS[modifier];
        for (const mod of incompatibleModifiers) {
            modifiersSet.delete(mod);
        }
        modifiersSet.add(modifier);
        chord.data.modifiers = Array.from(modifiersSet);
    }

    public unsetChordModifier(eventIndex: number, modifier: ChordModifier): void {
        const chord = this.getOrCreateChord(eventIndex);
        const modifiersSet = this.getChordModifiers(chord);
        modifiersSet.delete(modifier);
        chord.data.modifiers = Array.from(modifiersSet);
    }

    public setEventAnnotation(eventIndex: number, text: string | undefined): void {
        const tablatureEvent = this.tablature.events[eventIndex];

        // tablatureEvent may be null
        if (!tablatureEvent || isChordEvent(tablatureEvent)) {
            const chordEvent = this.getOrCreateChord(eventIndex);
            chordEvent.annotation = text?.trim();
        } else {
            tablatureEvent.annotation = text;
        }
    }

    private getChordModifiers(event: TablatureEvent<"chord">): Set<ChordModifier> {
        return new Set(event.data.modifiers || []);
    }

    private getOrCreateChord(eventIndex: number): TablatureEvent<"chord"> {
        const tablatureEvent = this.tablature.events[eventIndex];
        if (!isChordEvent(tablatureEvent)) {
            const event: TablatureEvent<"chord"> = {
                type: "chord",
                data: {
                    notes: [],
                },
            };
            this.tablature.events[eventIndex] = event;

            return event;
        }
        return tablatureEvent;
    }

    public updateNoteModifiers(position: TablaturePosition, modifiers: NoteModifier[]): void {
        if (position.stringIndex < 0 || position.stringIndex > this.tablature.stave.lineCount) {
            throw new Error(`Cannot update modifiers '${modifiers}' at string '${position.stringIndex}'.`);
        }
        const note = this.tablature.getNote(position);
        if (note === undefined || note.fret === "x") {
            return undefined;
        }

        note.modifiers = modifiers;
    }
}
