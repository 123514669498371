import type { TablaturePosition } from "../../interfaces/tablature-position";
import { clamp } from "../../utils/utils";
import type { EditorSelection } from "./editor-selection";

export class EditorNavigation {
    private editorSelection: EditorSelection;

    constructor(selection: EditorSelection) {
        this.editorSelection = selection;
    }

    public up(): TablaturePosition {
        return this.navigate(0, -1);
    }

    public down(): TablaturePosition {
        return this.navigate(0, 1);
    }

    public left(): TablaturePosition {
        return this.navigate(-1, 0);
    }

    public right(): TablaturePosition {
        return this.navigate(1, 0);
    }

    public top(): TablaturePosition {
        const position = this.editorSelection.getPosition();
        if (!position) {
            throw new Error("Cannot perform cursor move without a selection");
        }
        position.stringIndex = 0;
        this.editorSelection.set(position);
        return position;
    }

    public bottom(): TablaturePosition {
        const position = this.editorSelection.getPosition();
        if (!position) {
            throw new Error("Cannot perform cursor move without a selection");
        }
        position.stringIndex = this.maxString;
        this.editorSelection.set(position);
        return position;
    }

    private get maxString(): number {
        return this.editorSelection.stringCount - 1;
    }

    private navigate(eventDiff: number, stringDiff: number): TablaturePosition {
        const position = this.editorSelection.getPosition();
        if (!position) {
            throw new Error("Cannot perform cursor move without a selection");
        }
        const { eventIndex, stringIndex } = position;
        const newPosition: TablaturePosition = {
            eventIndex: clamp(eventIndex + eventDiff, 0),
            stringIndex: clamp(stringIndex + stringDiff, 0, this.maxString),
        };

        this.editorSelection.set(newPosition);
        return newPosition;
    }
}
