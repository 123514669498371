<template>
    <nav class="header navbar navbar-fixed-top">
        <div class="container">
            <div class="d-flex">
                <button
                    class="btn bi bi-arrow-left back"
                    @click="onNavigateBack"
                    :title="$t('back')"
                    @keydown.enter="onNavigateBack"
                    tabindex="0"></button>
                <page-details></page-details>
            </div>

            <div class="toolbar d-flex align-items-center gap-1">
                <user-dropdown-menu></user-dropdown-menu>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserDropdownMenu from "@components/user-dropdown-menu/user-dropdown-menu.vue";
import PageDetails from "@components/page-details/page-details.vue";

export default defineComponent({
    name: "settings-header",
    components: {
        "user-dropdown-menu": UserDropdownMenu,
        "page-details": PageDetails,
    },
    methods: {
        onNavigateBack(): void {
            this.$router.push({
                name: "dashboard",
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    background-color: var(--background-color-secondary);
    border-bottom: solid 1px var(--gray-300);
}

.toolbar-right {
    i {
        margin-right: 5px;
    }
}
</style>
