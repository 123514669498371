export interface NoteDTO {
    fret: Fret;
    modifiers: NoteModifier[];
}

export type Fret = number | "x";

// NOTE: These are coupled to KEYS
export enum NoteModifier {
    quarterBend = "quarter-bend",
    halfBend = "half-bend",
    bend = "bend",
    doubleBend = "double-bend", // Deprecated
    quarterBendDown = "quarter-bend-down",
    halfBendDown = "half-bend-down",
    bendDown = "bend-down",
    doubleBendDown = "double-bend-down", // Deprecated modifier
    hammerPull = "hammer-pull",
    slide = "slide",
    harmonic = "harmonic",
    tremoloPicking = "tremolo-picking",
    quarterPreBend = "quarter-pre-bend",
    halfPreBend = "half-pre-bend",
    preBend = "pre-bend",
    doublePreBend = "double-pre-bend",
}

export type BendModifier =
    | NoteModifier.quarterBend
    | NoteModifier.halfBend
    | NoteModifier.bend
    | NoteModifier.doubleBend;

export type BendDownModifier =
    | NoteModifier.quarterBendDown
    | NoteModifier.halfBendDown
    | NoteModifier.bendDown
    | NoteModifier.doubleBendDown;

export type PreBendModifier =
    | NoteModifier.quarterPreBend
    | NoteModifier.halfPreBend
    | NoteModifier.preBend
    | NoteModifier.doublePreBend;

export function isNote(value: unknown): value is number | "x" {
    return value === "x" || value === "X" || !Number.isNaN(Number(value));
}

export function isFret(value: unknown): value is number {
    return !Number.isNaN(Number(value));
}
