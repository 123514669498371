<template>
    <event-annotation v-if="showAnnotation && annotation" :text="annotation" :padding="annotationPadding" />

    <chord
        v-if="isChordEvent(event)"
        :geometry="geometry"
        :tablature="tablature"
        :event="event"
        :eventIndex="eventIndex" />
    <bar-line v-else-if="event.data === 'Single'" :geometry="geometry" />
    <double-bar-line v-else-if="event.data === 'Double'" :geometry="geometry" />
    <repeat-bar-line
        v-else-if="event.data === 'Repeat'"
        :geometry="geometry"
        :repeatCircles="2"
        :begin-repeat="isBeginRepeat" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Chord from "./chord.vue";
import BarLine from "./bar-line/bar-line.vue";
import DoubleBarLine from "./bar-line/double-bar-line.vue";
import RepeatBarLine from "./bar-line/repeat-bar-line.vue";
import EventAnnotation from "./event-annotation.vue";
import { type TablatureEvent, BarLineType } from "@common/tablature-event";
import type { Point } from "../../models/point";
import { pointToTransform } from "../../utils/utils";
import { isBarLineEvent, isChordEvent } from "../../utils/tablature-events-utils";
import { countBarLineEvents } from "../../utils/count-events";
import { Geometry } from "../../services/renderer/geometry";
import { Tablature } from "../../models/song/tablature";

export default defineComponent({
    name: "event",
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
        tablature: {
            type: Tablature as PropType<Tablature>,
            required: true,
        },
        event: {
            type: Object as PropType<TablatureEvent>,
            required: true,
        },
        showAnnotation: {
            type: Boolean,
            required: true,
        },
        eventIndex: {
            type: Number as PropType<number>,
            required: true,
        },
    },
    components: {
        chord: Chord,
        "bar-line": BarLine,
        "double-bar-line": DoubleBarLine,
        "repeat-bar-line": RepeatBarLine,
        "event-annotation": EventAnnotation,
    },

    computed: {
        annotationPadding(): number {
            return this.geometry.style.annotationPadding;
        },
        annotation(): string | undefined {
            return this.event.annotation;
        },
        eventTransform(): string {
            const point = this.eventPoint;
            return pointToTransform(point);
        },
        eventPoint(): Point {
            return this.geometry.getEventPoint(this.eventIndex);
        },
        isBeginRepeat(): boolean {
            if (!isBarLineEvent(this.event)) return false;

            const barlineType = this.event.data;

            if (barlineType !== BarLineType.Repeat) {
                throw new Error("Cannot call 'isBeginRepeat' in a not repeat barline event");
            }

            const repeatCount = countBarLineEvents(this.tablature.getAll(), this.eventIndex, BarLineType.Repeat);

            const isEvenRepeat = Boolean(repeatCount % 2);
            return !isEvenRepeat;
        },
    },
    methods: {
        isChordEvent(event: TablatureEvent | undefined): event is TablatureEvent<"chord"> {
            return isChordEvent(event);
        },
    },
});
</script>
