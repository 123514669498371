<template>
    <g>
        <path :d="path" />
        <path v-if="isSplit" :d="pathB" />
    </g>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import type { TablaturePosition } from "../../../../interfaces/tablature-position";
import { Geometry } from "../../../../services/renderer/geometry";
import { Point } from "../../../../models/point";

export default defineComponent({
    name: "hammer-pull",
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
        from: {
            type: Object as PropType<TablaturePosition>,
            required: true,
        },
        to: {
            type: Object as PropType<TablaturePosition>,
            required: true,
        },
    },
    computed: {
        path(): string {
            const from = this.geometry.event.getNotePoint(this.from.stringIndex);
            const eventDiff = this.to.eventIndex - this.from.eventIndex;
            const to = this.geometry.event.getNotePoint(this.to.stringIndex, eventDiff);

            return this.createPath(from, to);
        },
        pathB(): string {
            const absoluteToPoint = this.geometry.tablaturePositionToPoint(this.to);
            const relativeTo = this.geometry.transformToLocalCoords(this.from.eventIndex, absoluteToPoint);
            const eventDiff = this.to.eventIndex - this.from.eventIndex;
            const pointDiff = this.geometry.event.getNotePoint(this.to.stringIndex, eventDiff);
            const from = relativeTo.minus(new Point(pointDiff.x, 0));

            return this.createPath(from, relativeTo);
        },
        isSplit(): boolean {
            return this.geometry.lastEventInRow(this.from.eventIndex) < this.to.eventIndex;
        },
    },
    methods: {
        getPointStr(p: Point): string {
            return `${p.x} ${p.y}`;
        },
        createPath(from: Point, to: Point): string {
            const paddedFromX = from.x + 8;
            const paddedToX = to.x - 8;
            const paddedY = from.y - 3;

            const originPoint = new Point(paddedFromX, paddedY);
            const targetPoint = new Point(paddedToX, paddedY);

            const halfX = paddedFromX + (paddedToX - paddedFromX) / 2;
            const controlPoint1 = new Point(halfX, paddedY - 13);
            const controlPoint2 = new Point(halfX, paddedY - 9);

            const originStr = `${this.getPointStr(originPoint)}`;
            const targetStr = `${this.getPointStr(targetPoint)}`;

            // "M0 0 Q13 -9, 27 0 Q13 -11,0 0Z"
            const curvePath1 = `Q${this.getPointStr(controlPoint1)},${targetStr}`;
            const curvePath2 = `Q${this.getPointStr(controlPoint2)},${originStr}`;

            return `M${originStr} ${curvePath1} ${curvePath2}Z`;
        },
    },
});
</script>

<style lang="scss" scoped>
path {
    stroke: var(--text-color);
    stroke-width: 0;
    fill: var(--text-color);
}
</style>
