<template>
    <g :transform="transform">
        <line x1="-2" y1="0" x2="-2" :y2="barHeight" />
        <line x1="2" y1="0" x2="2" :y2="barHeight" :stroke-width="3" />
    </g>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Point } from "../../../models/point";
import { Geometry } from "../../../services/renderer/geometry";

export default defineComponent({
    name: "end-line",
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
        rowsCount: {
            type: Number as PropType<number>,
            required: true,
        },
    },
    computed: {
        eventPosition(): Point {
            const width = this.geometry.getStaveWidth();
            const eventPoint = this.geometry.getRowPoint(this.rowsCount - 1);

            return eventPoint.plus(new Point(width, 0));
        },
        transform(): string {
            const offset = new Point(-3, 0);
            const originPoint = this.eventPosition.plus(offset);
            return `translate(${originPoint.x}, ${originPoint.y})`;
        },
        barHeight(): number {
            return this.geometry.getRowHeight();
        },
    },
});
</script>

<style lang="scss" scoped>
line {
    stroke: var(--text-color);
}
</style>
