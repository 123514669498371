<template>
    <li
        v-if="!hide"
        class="btn"
        :class="{ disabled, flat }"
        :title="tooltip"
        @click.prevent="onDisplayShareModal"
        @keydown.enter.prevent="onDisplayShareModal"
        tabindex="0">
        <i class="bi" :class="{ 'bi-share-fill': isPublic, 'bi-share': !isPublic }"></i>
        <span v-if="!hideText">{{ $t("actionShare") }}</span>
    </li>

    <song-action-share-modal
        v-if="isShareModalVisible"
        :song="song"
        @close="onCloseShareModal"></song-action-share-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Song } from "../../../models/song/song";
import { SongActionOptions } from "../../../interfaces/song-actions";
import { isWhitespaceString } from "../../../utils/utils";
import { shouldHideAction } from "../../../utils/song-actions-utils";
import { SongVisibility } from "@common/song-metadata";
import SongActionShareModal from "./song-action-share-modal.vue";

export default defineComponent({
    name: "song-action-share",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        songActionOptions: {
            type: Object as PropType<SongActionOptions>,
            default: () => ({}),
        },
        flat: {
            type: Boolean,
            default: false,
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        "song-action-share-modal": SongActionShareModal,
    },
    data() {
        return {
            isShareModalVisible: false,
        };
    },
    computed: {
        hide(): boolean {
            return shouldHideAction("share", this.song, this.songActionOptions);
        },
        disabled(): boolean {
            return this.songActionOptions.disabled === true;
        },
        currentVisibility(): SongVisibility {
            return this.song.metadata.visibility;
        },
        isPublic(): boolean {
            return this.currentVisibility === "public";
        },
        tooltip(): string {
            return this.isPublic
                ? `${this.$t("visibilityPublic")}\n${this.$t("visibilityPublicDescription")}`
                : `${this.$t("visibilityPrivate")}\n${this.$t("visibilityPrivateDescription")}`;
        },
        songName(): string {
            return isWhitespaceString(this.song.title) ? this.$t("songUntitled") : this.song.title;
        },
        isLoggedIn(): boolean {
            return this.$store.user.loggedIn;
        },
    },
    methods: {
        async onDisplayShareModal(): Promise<void> {
            if (this.disabled) {
                return;
            }

            if (!this.isLoggedIn) {
                await this.$router.push({
                    name: "signin",
                });
                return;
            }

            const uid = this.$store.user.user?.uid;
            if (!uid) throw new Error("UID for user not found when sharing");

            this.isShareModalVisible = true;
        },
        onCloseShareModal(): void {
            this.isShareModalVisible = false;
        },
    },
});
</script>
