<template>
    <div v-if="isNotebook && isEmpty" class="empty-notebook-warning alert alert-warning" role="alert">
        <div class="d-flex gap-2">
            <i class="bi bi-journal"></i>
            <span>{{ $t("autodeleteEmptyNotebookWarning") }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { SongStatus } from "@common/song-status";
import { defineComponent } from "vue";

export default defineComponent({
    name: "empty-notebook-warning",
    computed: {
        isNotebook(): boolean {
            return this.$route.name === "notebook";
        },
        selectedNotebook(): string | undefined {
            return this.$store.config.selectedNotebook;
        },
        songsCount(): number {
            const notebook = this.selectedNotebook;
            return (
                this.$store.song.find({ status: SongStatus.Default, notebook }).length +
                this.$store.song.find({ status: SongStatus.Pinned, notebook }).length
            );
        },
        isEmpty(): boolean {
            return this.songsCount === 0;
        },
    },
});
</script>

<style lang="scss" scoped>
.empty-notebook-warning {
    margin-top: -15px;
}
</style>
