<template>
    <nav class="header navbar navbar-fixed-top">
        <div class="container container-fluid">
            <div class="toolbar toolbar-left gap-1">
                <button
                    class="btn bi bi-list d-xxl-none menu"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvas-sidebar"
                    aria-controls="offcanvas-sidebar"
                    @keydown.enter="toggleOffcanvas"
                    tabindex="0"></button>
                <div class="page-name d-xxl-none">{{ $t("linkSingin") }}</div>
            </div>

            <div class="toolbar toolbar-right d-flex"></div>

            <div class="toolbar d-flex align-items-center gap-1">
                <user-dropdown-menu></user-dropdown-menu>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserDropdownMenu from "@components/user-dropdown-menu/user-dropdown-menu.vue";

export default defineComponent({
    name: "signin-header",
    components: {
        "user-dropdown-menu": UserDropdownMenu,
    },
    methods: {
        toggleOffcanvas(event: KeyboardEvent): void {
            const button = event.target as HTMLButtonElement;
            button.click();
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    background-color: var(--background-color-secondary);
    border-bottom: solid 1px var(--gray-300);
}
</style>
