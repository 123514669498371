import type { Fret } from "@common/note";
import type { TablaturePosition } from "../../../../interfaces/tablature-position";
import { sanitizeFret } from "../../../../utils/utils";
import { EditorCommand } from "../editor-command";
import { deleteSelectionRange } from "../utils/delete-selection-range";

export class SetNoteFretCommand extends EditorCommand {
    private fret: Fret;
    private singleDigitInput: boolean;

    constructor({ fret, singleDigitInput }: { fret: Fret; singleDigitInput?: boolean }) {
        super();
        this.fret = fret;
        this.singleDigitInput = Boolean(singleDigitInput);
    }

    public onExecute(): void {
        if (!this.editorSelection.getPosition()) return;

        deleteSelectionRange(this.tablatureEditor, this.editorSelection);
        const selectedPosition = this.editorSelection.getPosition();
        if (!selectedPosition) throw new Error("[SetNoteFretCommand] Error after deselecting range");

        const fret = this.parseInputFret(selectedPosition);
        this.tablatureEditor.updateNoteFret(selectedPosition, fret);
    }

    private parseInputFret(selectedPosition: TablaturePosition): Fret | undefined {
        if (this.singleDigitInput) {
            return this.handleSingleDigitInput(selectedPosition);
        } else {
            return sanitizeFret(this.fret);
        }
    }

    private handleSingleDigitInput(selectedPosition: TablaturePosition): Fret | undefined {
        const note = this.tablature.getNote(selectedPosition);
        const currentFret = note?.fret;
        if (currentFret === 1 || currentFret == 2) {
            return sanitizeFret(`${currentFret}${this.fret}`);
        }
        return sanitizeFret(this.fret);
    }
}
