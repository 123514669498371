type StorageItems = "zoomValue" | "showGridMode" | "userLocale" | "songTransactionLog" | "dismissedLoginWarning";

export class LocalStorage {
    constructor() {
        if (!localStorage) {
            console.warn("localstorage is not available on this browser, local settings will not be saved");
        }
    }

    public get(key: StorageItems): string | undefined {
        if (!localStorage) return undefined;
        const result = localStorage.getItem(key);
        if (result === null) return undefined;
        return result;
    }

    public getBoolean(key: StorageItems, defaultValue = false): boolean {
        const stringValue = this.get(key);
        if (stringValue === undefined) return defaultValue;
        return stringValue === "true";
    }

    public getNumber(key: StorageItems): number | undefined {
        const valueString = this.get(key);
        if (!valueString) return undefined;

        const parsedValue = parseFloat(valueString);
        if (isNaN(parsedValue)) {
            throw new Error(`Error parsing ${key} from localStorage, value ${valueString} is not a number`);
        }
        return parsedValue;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getObject<T = any>(key: StorageItems): T | undefined {
        const stringValue = this.get(key);
        if (!stringValue) return undefined;
        return JSON.parse(stringValue);
    }

    public set(key: StorageItems, value: number | string | boolean | Record<string, unknown>): void {
        if (!localStorage) return;

        if (typeof value === "object") {
            const parsedObject = JSON.stringify(value);
            localStorage.setItem(key, parsedObject);
        } else {
            localStorage.setItem(key, value.toString());
        }
    }

    public remove(key: StorageItems): void {
        if (localStorage) {
            localStorage.removeItem(key);
        }
    }

    public clear(): void {
        if (localStorage) {
            localStorage.clear();
        }
    }
}
