export function nullToUndefined(obj: Record<string, unknown>): Record<string, unknown> {
    for (const key in obj) {
        if (obj[key] === null) {
            obj[key] = undefined;
        } else if (typeof obj[key] === "object") {
            nullToUndefined(obj[key] as Record<string, unknown>);
        }
    }
    return obj;
}
