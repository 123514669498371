import type { TablatureEvent } from "../../common/tablature-event";

export function isChordEvent(event: TablatureEvent | undefined): event is TablatureEvent<"chord"> {
    return !!event && event.type === "chord";
}

export function isBarLineEvent(event: TablatureEvent | undefined): event is TablatureEvent<"barline"> {
    return !!event && event.type === "barline";
}
export function isNewlineEvent(event: TablatureEvent | undefined): event is TablatureEvent<"newline"> {
    return !!event && event.type === "newline";
}

export function isEmptyEvent(event: TablatureEvent | undefined): boolean {
    if (isChordEvent(event)) {
        return isEmptyChord(event);
    }
    if (event == undefined) return true;
    if (event.annotation) return false;
    return event.type === undefined;
}

function isEmptyChord(event: TablatureEvent<"chord">): boolean {
    const chord = event.data;
    const notes = chord.notes;
    const chordModifiers = chord.modifiers || [];
    const hasChordModifiers = chordModifiers.some((mod) => mod !== undefined);
    const hasNotes = typeof notes !== "string" && notes.some((fret) => fret !== undefined);
    return !hasNotes && !hasChordModifiers;
}
