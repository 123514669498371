import { EditorCommand } from "../editor-command";
import { deleteSelectionRange } from "../utils/delete-selection-range";

export class InsertEventCommand extends EditorCommand {
    public onExecute(): void {
        const selectionPosition = this.editorSelection.getPosition(); // can cast as it is using deleteSelectionRange before
        if (!selectionPosition) return;

        deleteSelectionRange(this.tablatureEditor, this.editorSelection);

        this.tablatureEditor.insertEventsAfter(selectionPosition.eventIndex, undefined);
        this.editorSelection.cursor.nextChord();
    }
}
