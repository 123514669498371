import type { INSTRUMENT_OPTIONS, SongInstrument } from "@common/constants";
import { DEFAULT_INSTRUMENT, DEFAULT_INSTRUMENT_OPTIONS, DEFAULT_TUNING } from "@common/constants";
import type { SharedSongDTO, SongDTO } from "@common/dtos/song.dto";
import type { SongMetadata } from "@common/song-metadata";
import { SongStatus } from "@common/song-status";
import type { SongTags } from "@common/song-tags";
import type { TuningOption } from "@common/types";
import { resizeArray } from "../../utils/resize-array";
import { Tablature } from "./tablature";

export type SongConfig = {
    id: string;
} & Omit<SongDTO, "version">;

export class Song {
    public id: string;
    public title: string;
    public tags: SongTags;
    public tablature: Tablature;
    public status: SongStatus;
    public metadata: SongMetadata;
    public tuning: TuningOption[] = [];

    constructor(config: SongConfig) {
        this.id = config.id;
        this.title = config.title;
        this.tags = config.tags;
        this.tablature = new Tablature(config.tablature);
        this.status = config.status;
        this.metadata = config.metadata;
        this.tuning = config.tuning || DEFAULT_INSTRUMENT_OPTIONS.guitar.tuning;
    }

    public isEditable(): boolean {
        return !this.isInTrash();
    }

    public isInTrash(): boolean {
        return this.status === SongStatus.Trashed;
    }

    public isArchived(): boolean {
        return this.status === SongStatus.Archived;
    }

    public isPinned(): boolean {
        return this.status === SongStatus.Pinned;
    }

    public isDefault(): boolean {
        return this.status === SongStatus.Default;
    }

    public updateTuning(tuning: TuningOption[]): void {
        this.tuning = resizeArray(tuning, this.tablature.lineCount, "E");
    }

    public updateInstrument(instrument: SongInstrument): void {
        this.tags.instrument = instrument;
        const defaultOptions = this.getDefaultInstrumentOptions();
        this.tablature.updateStrings(defaultOptions.strings);
        this.updateTuning(defaultOptions.tuning);
    }

    public toDTO(): SongDTO {
        return {
            title: this.title,
            tags: this.tags,
            tablature: this.tablature.toDTO(),
            status: this.status,
            metadata: { ...this.metadata },
            tuning: this.tuning || DEFAULT_TUNING,
            version: APP_VERSION,
        };
    }

    /** Copy the shared song */
    public toSharedSongDTO(): SharedSongDTO {
        return {
            title: this.title,
            tags: this.tags,
            tablature: this.tablature.toDTO(),
            tuning: this.tuning || DEFAULT_TUNING,
            status: SongStatus.Default,
        };
    }

    public copy(): Song {
        return new Song({ ...this.toDTO(), id: this.id });
    }

    public sanitize(): this {
        this.tablature.sanitize();
        this.tuning = resizeArray(this.tuning, this.tablature.lineCount, "E");
        return this;
    }

    private getDefaultInstrumentOptions(): INSTRUMENT_OPTIONS {
        const instrument = this.tags.instrument || DEFAULT_INSTRUMENT;
        return DEFAULT_INSTRUMENT_OPTIONS[instrument];
    }
}
