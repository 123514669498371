<template>
    <div class="page-details-container">
        <div v-if="isDashboard" class="current-page my-songs icon">
            <i class="bi bi-music-note"></i>
            <span class="page-name">
                {{ $t("linkSongs") }}
            </span>
        </div>
        <div v-if="isNotebook" class="current-page selected-notebook icon">
            <a
                id="dropdownNotebookMenuButton"
                class="btn btn-hover-gray-light notebook-dropdown-menu dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                :aria-label="$t('toggleUserMenu')"
                :title="$t('toggleUserMenu')">
                <i class="bi bi-journal-bookmark-fill"></i>
                <span class="page-name">
                    {{ selectedNotebook }}
                </span>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownNotebookMenuButton">
                <li>
                    <div class="dropdown-header">
                        {{ selectedNotebook }}
                    </div>
                </li>
                <hr class="dropdown-divider" />

                <li>
                    <a
                        class="btn dropdown-item"
                        :class="{ disabled }"
                        @click="onDisplayRenameNotebookModal"
                        @keydown.enter="onDisplayRenameNotebookModal"
                        tabindex="0">
                        <i class="bi bi-journal-code"></i>
                        {{ $t("actionRenameNotebook") }}
                    </a>
                </li>
                <li>
                    <a
                        class="btn dropdown-item"
                        :class="{ disabled }"
                        @click.prevent="onDeleteNotebookRequestConfirmation"
                        @keydown.enter.prevent="onDeleteNotebookRequestConfirmation"
                        tabindex="0">
                        <i class="bi bi-journal-x"></i>
                        {{ $t("actionDeleteNotebook") }}
                    </a>
                </li>
            </ul>
        </div>
        <div v-if="isArchive" class="current-page archive icon">
            <i class="bi bi-archive"></i>
            <span class="page-name">
                {{ $t("linkArchive") }}
            </span>
        </div>
        <div v-if="isTrash" class="current-page trash icon">
            <i class="bi bi-trash"></i>
            <span class="page-name">
                {{ $t("linkTrash") }}
            </span>
        </div>
        <div v-if="isSettings" class="current-page settings icon">
            <i class="bi bi-gear"></i>
            <span class="page-name">
                {{ $t("linkSettings") }}
            </span>
        </div>
    </div>

    <rename-notebook-modal
        v-if="isRenameNotebookModalVisible"
        :selectedNotebook="selectedNotebook"
        @close="onCloseRenameNotebookModal"></rename-notebook-modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SongStatus } from "@common/song-status";
import { Song } from "../../models/song/song";
import { api } from "../../apis/api";
import RenameNotebookModal from "./rename-notebook-modal.vue";
import Swal from "sweetalert2";

export default defineComponent({
    name: "page-details",
    data() {
        return {
            actionInProgress: false,
            isRenameNotebookModalVisible: false,
        };
    },
    components: {
        "rename-notebook-modal": RenameNotebookModal,
    },
    computed: {
        isDashboard(): boolean {
            return this.$route.name === "dashboard";
        },
        isNotebook(): boolean {
            return this.$route.name === "notebook";
        },
        isArchive(): boolean {
            return this.$route.name === "archive";
        },
        isTrash(): boolean {
            return this.$route.name === "trash";
        },
        isSettings(): boolean {
            return this.$route.name === "settings";
        },
        selectedNotebook(): string | undefined {
            return this.$store.config.selectedNotebook;
        },
        songsAtSelectedNotebook(): Song[] {
            const notebook = this.selectedNotebook;
            const normalNotebookSongs = this.$store.song.find({ status: SongStatus.Default, notebook });
            const pinnedNotebookSongs = this.$store.song.find({ status: SongStatus.Pinned, notebook });
            return normalNotebookSongs.concat(pinnedNotebookSongs);
        },
        disabled(): boolean {
            return this.actionInProgress;
        },
    },
    methods: {
        async onDeleteNotebookRequestConfirmation(): Promise<void> {
            const result = await Swal.fire({
                title: this.$t("actionDeleteNotebookConfirmationTitle", { notebook: this.selectedNotebook }),
                text: this.$t("actionDeleteNotebookConfirmationText"),
                showCancelButton: true,
                cancelButtonText: this.$t("actionCancel"),
                confirmButtonText: this.$t("actionDelete"),
                customClass: {
                    confirmButton: "btn-danger",
                },
            });

            if (result.isConfirmed) {
                this.moveToMySongs();
            }
        },
        async moveToMySongs(): Promise<void> {
            api.log.track("deleteNotebook");
            this.actionInProgress = true;
            for (const song of this.songsAtSelectedNotebook) {
                const updatedSong = song.copy();
                updatedSong.metadata.labels = [];
                await this.$store.song.update(updatedSong);
            }
            this.actionInProgress = false;
            this.$bus.emit("info", this.$t("actionCompleteDeleteNotebook"));
            this.$router.push({ name: "dashboard" });
        },

        onDisplayRenameNotebookModal(): void {
            this.isRenameNotebookModalVisible = true;
        },
        onCloseRenameNotebookModal(): void {
            this.isRenameNotebookModalVisible = false;
        },
    },
});
</script>

<style lang="scss" scoped>
.page-details-container {
    min-width: 0;

    .current-page {
        background-color: var(--background-color-secondary);
        align-items: center;
        color: var(--gray-700);
        display: flex;
        white-space: nowrap;
        gap: 5px;

        &.selected-notebook {
            position: relative;
            padding: 0;

            .notebook-dropdown-menu {
                min-width: 63px;
            }
        }

        .page-name {
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 0;
        }
    }
}
</style>
