<template>
    <div class="hg-candidate-box" :class="{ hide: hide }">
        <div class="hg-candidate-box-prev"></div>
        <ul class="hg-candidate-box-list">
            <li class="hg-candidate-box-list-item" @click="onClick('hammer-pull')">
                <div class="fret">{{ fret }}</div>
                <img class="hp-key" alt="Hammer Pull Key" />
            </li>
            <li class="hg-candidate-box-list-item" @click="onClick('slide')">
                <div class="fret">{{ fret }}</div>
                <img class="slide-key" alt="Slide Key" />
            </li>
            <li class="hg-candidate-box-list-item" @click="onClick('bend')">
                <div class="fret">{{ fret }}</div>
                <img class="bend-key" alt="Bend Key" />
            </li>
            <li class="hg-candidate-box-list-item" @click="onClick('pre-bend')">
                <div class="fret">{{ fret }}</div>
                <img class="pre-bend-key" alt="Pre Bend Key" />
            </li>
            <li class="hg-candidate-box-list-item" @click="onClick('bend-down')">
                <div class="fret">{{ fret }}</div>
                <img class="bend-down-key" alt="Bend Down Key" />
            </li>
        </ul>
        <div class="hg-candidate-box-next"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import type { Fret } from "../../../common/note";

export default defineComponent({
    name: "suggestions-overlay",
    emits: {
        onClick(_suggestion: string) {
            return true;
        },
    },
    props: {
        hide: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
        fret: {} as {
            type: PropType<Fret>;
        },
    },
    methods: {
        onClick(suggestion: string) {
            this.$emit("onClick", suggestion);
        },
    },
});
</script>

<style lang="scss" scoped>
.hg-candidate-box {
    background: transparent;
    border-bottom: none;
    border-radius: 5px;
    box-shadow: var(--shadow-sm);
    font-family:
        HelveticaNeue-Light,
        Helvetica Neue Light,
        Helvetica Neue,
        Helvetica,
        Arial,
        Lucida Grande,
        sans-serif;
    margin-left: 2px;
    margin-top: -5px;
    min-width: 40%;
    position: absolute;
    transform: translateY(0);
    transition: all 0.2s ease-in-out;
    top: -52px;
    user-select: none;
    z-index: 10;

    &.hide {
        transform: translateY(115%);
    }
}

.hg-candidate-box-next,
.hg-candidate-box-prev {
    display: none;
}

ul.hg-candidate-box-list {
    background: var(--keyboard-primary-color);
    border-radius: 5px;
    display: flex;
    flex: 1;
    gap: 1px;
    justify-content: space-around;
    list-style: none;
    margin: 0;
    padding: 0;
}
li.hg-candidate-box-list-item {
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 52px;
    justify-content: space-between;
    padding: 5px 6px;
    width: 64px;

    &:first-child {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        border-left: solid 3px var(--keyboard-primary-dark-color);
    }
    &:last-child {
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        border-right: solid 3px var(--keyboard-primary-dark-color);
    }
    &:not(:last-child) {
        border-right: solid 1px var(--keyboard-primary-dark-color);
    }
    .fret {
        flex-grow: 1;
        text-align: center;
    }
}
</style>
