<template>
    <nav v-hide-on-scroll="'#layout-content'" class="header navbar with-sidebar">
        <div class="container">
            <div class="navbar-section navigation d-flex">
                <button
                    class="btn btn-hover-gray-light bi bi-list d-xxl-none menu"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvas-sidebar"
                    aria-controls="offcanvas-sidebar"
                    :aria-label="$t('toggleSideMenu')"
                    :title="$t('toggleSideMenu')"
                    @keydown.enter="toggleOffcanvas"
                    tabindex="0"></button>
                <page-details></page-details>
            </div>

            <div class="navbar-section toolbar d-flex">
                <div class="search-container" :class="{ 'with-value': searchHasValue }">
                    <input
                        class="search expand-right form-control"
                        id="searchright"
                        type="search"
                        :placeholder="searchPlaceholder"
                        @input="onSearchChange"
                        @focus="onSearchFocus"
                        @blur="onSearchBlur" />
                    <label
                        class="button search-button"
                        for="searchright"
                        :aria-label="$t('searchSongs')"
                        :title="$t('searchSongs')"
                        ><i class="icon bi bi-search"></i
                    ></label>
                </div>

                <button
                    class="btn btn-hover-gray-light bi bi-grid"
                    :class="{ 'bi-view-stacked': showGridMode, 'bi-grid': !showGridMode }"
                    @click="toggleViewMode"
                    @keydown.enter="toggleViewMode"
                    :title="$t('toggleViewMode')"
                    tabindex="0"></button>

                <user-dropdown-menu :use-light-style="true"></user-dropdown-menu>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserDropdownMenu from "../user-dropdown-menu/user-dropdown-menu.vue";
import PageDetails from "../page-details/page-details.vue";

export default defineComponent({
    name: "main-header",
    props: {
        searchPlaceholder: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            searchHasValue: false,
        };
    },
    emits: {
        searchChange: (_value: string) => true,
        searchFocus: () => true,
        searchBlur: () => true,
    },
    components: {
        "user-dropdown-menu": UserDropdownMenu,
        "page-details": PageDetails,
    },
    computed: {
        showGridMode(): boolean {
            return this.$store.config.showGridMode;
        },
        search(): HTMLElement {
            return this.$refs.search as HTMLElement;
        },
    },
    methods: {
        toggleViewMode(): void {
            this.$store.config.toggleViewMode();
        },
        toggleOffcanvas(event: KeyboardEvent): void {
            const button = event.target as HTMLButtonElement;
            button.click();
        },
        onSearchChange(event: Event): void {
            const target = <HTMLInputElement>event.target;
            this.searchHasValue = !!target.value;
            this.$emit("searchChange", target.value);
        },
        onSearchFocus(): void {
            this.$emit("searchFocus");
        },
        onSearchBlur(event: Event): void {
            this.$emit("searchBlur");
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    background-color: var(--background-color-secondary);
    border-bottom: solid 1px var(--gray-300);
    display: flex;
    flex-wrap: nowrap;

    .navbar-section {
        align-items: center;

        &.navigation {
            min-width: 0;
        }

        &.toolbar {
            flex-grow: 1;
            justify-content: flex-end;

            .search-container {
                display: inline-block;
                height: 40px;
                margin-right: 2px;
                position: relative;
                transition: all 0.4s ease;
                vertical-align: bottom;
                width: 40px;
                min-width: 40px;

                &:focus-within,
                &.with-value {
                    position: absolute;
                    right: 109px;
                    width: calc(100% - 165px);
                }

                .search {
                    height: 100%;
                    max-width: 210px;
                    position: absolute;
                    padding-right: 25px;
                    right: 0;
                    width: 100%;
                    z-index: 10;

                    &:focus,
                    &:not(:placeholder-shown) {
                        padding-right: 12px;

                        & + .search-button {
                            opacity: 0;
                        }
                    }

                    &:placeholder-shown {
                        text-overflow: ellipsis;
                    }

                    @media (min-width: 576px) {
                        min-width: 210px;
                    }

                    @media (min-width: 768px) {
                        min-width: 280px;
                    }
                }

                .search-button {
                    pointer-events: none;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: opacity 0.2s ease;
                    width: 40px;
                    z-index: 100;
                }
            }
        }
    }

    @media (min-width: 1400px) {
        // Header should be sticky for desktop resolution
        transform: translateY(0px) !important;
        opacity: 1 !important;
    }
}
</style>
