import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import type { PiniaPluginContext } from "pinia";
import { createPinia } from "pinia";
import { registerSW } from "virtual:pwa-register";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { api } from "./apis/api";
import { ApiConfig } from "./apis/config";
import App from "./app.vue";
import { bus } from "./bus";
import { hideOnScrollDirective } from "./directives/hide-on-scroll/hide-on-scroll";
import de from "./locales/de.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
import fil from "./locales/fil.json";
import fr from "./locales/fr.json";
import hi from "./locales/hi.json";
import id from "./locales/id.json";
import it from "./locales/it.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import nl from "./locales/nl.json";
import pl from "./locales/pl.json";
import pt from "./locales/pt.json";
import ru from "./locales/ru.json";
import sv from "./locales/sv.json";
import tr from "./locales/tr.json";
import zh from "./locales/zh.json";
import { getRouter } from "./router/router";
import { getClipboardStore } from "./stores/clipboard.store";
import { getConfigStore } from "./stores/config.store";
import { getEditorStore } from "./stores/editor.store";
import { getInstallStore } from "./stores/install.store";
import { getSongStore } from "./stores/song.store";
import { getUserStore } from "./stores/user.store";
import { getZoomStore } from "./stores/zoom.store";

const app = createApp(App);
/// Setup Bugsnag
Bugsnag.start({
    apiKey: "e7d80b6feb0e37c41ce4e9bc868566b7",
    plugins: [new BugsnagPluginVue()],
    collectUserIp: false,
    autoTrackSessions: false,
    releaseStage: ApiConfig.releaseStage,
    enabledReleaseStages: ["production", "staging"],
    appVersion: APP_VERSION,
    onError: function (_event) {},
});
const bugsnagVue = Bugsnag.getPlugin("vue");
if (bugsnagVue) {
    app.use(bugsnagVue);
} else {
    api.log.error("Bugsnag Vue not available");
}

// Setup Localization
const locale = api.localStorage.get("userLocale") ?? navigator.language.split("-")[0] ?? "en";

type Locales =
    | "de"
    | "en"
    | "es"
    | "fil"
    | "fr"
    | "hi"
    | "id"
    | "it"
    | "ja"
    | "ko"
    | "nl"
    | "pl"
    | "pt"
    | "ru"
    | "sv"
    | "tr"
    | "zh";
type TranslationSchema = typeof en;

// If types fail in messages, you probably missed a translation in locales/*.json
const i18n = createI18n<[TranslationSchema], Locales>({
    legacy: false,
    locale,
    fallbackLocale: "en",
    messages: {
        de,
        en,
        es,
        fil,
        fr,
        hi,
        id,
        it,
        ja,
        ko,
        nl,
        pl,
        pt,
        ru,
        sv,
        tr,
        zh,
    },
});
app.use(i18n);

/// Setup Pinia
const myPlugin = ({ store }: PiniaPluginContext) => {
    store.$bus = bus;
    store.$t = (...args: Parameters<typeof i18n.global.t>) => i18n.global.t(...args);
};
const pinia = createPinia();
pinia.use(myPlugin);
app.use(pinia);

const userStore = getUserStore();

// Setup ServiceWorker
// const updateCheckInterval = 10 * 60 * 1000;
userStore.swReload = registerSW({
    // onRegistered(r) {
    //     r &&
    //         setInterval(() => {
    //             // Check if service worker should be updated
    //             r.update();
    //         }, updateCheckInterval);
    // },
    onNeedRefresh() {
        userStore.needReload = true;
    },
});

/// Setup Global properties
app.config.globalProperties.$bus = bus;
app.config.globalProperties.$store = {
    clipboard: getClipboardStore(),
    config: getConfigStore(),
    editor: getEditorStore(),
    install: getInstallStore(),
    song: getSongStore(),
    user: getUserStore(),
    zoom: getZoomStore(),
};

/// Setup Router
const router = getRouter();
app.use(router);

/// Setup Directives
app.directive("hide-on-scroll", hideOnScrollDirective);

/// Mount app
app.mount("#app");
