<template>
    <g v-for="index in rows" :transform="getRowTransform(index - 1)">
        <stave-row :geometry="geometry" :rowIndex="index" :canEdit="canEdit"></stave-row>
    </g>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import StaveRow from "@components/tablature/stave-row.vue";
import { pointToTransform } from "../../utils/utils";
import type { Point } from "../../models/point";
import { Geometry } from "../../services/renderer/geometry";

export default defineComponent({
    name: "stave",
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
        rows: {
            type: Number as PropType<number>,
            required: true,
        },
        canEdit: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    components: {
        "stave-row": StaveRow,
    },
    methods: {
        getRowTransform(rowIndex: number): string {
            return pointToTransform(this.getRowPoint(rowIndex));
        },
        getRowPoint(rowIndex: number): Point {
            return this.geometry.getRowPoint(rowIndex);
        },
    },
});
</script>
