<template>
    <li
        v-if="!hide"
        class="btn"
        :class="{ disabled, flat }"
        :title="tooltip"
        @click.prevent="onTogglePin"
        @keydown.enter.prevent="onTogglePin"
        tabindex="0">
        <i class="bi" :class="{ 'bi-pin-fill': isPinned, 'bi-pin': !isPinned }"></i>
        <span v-if="!hideText">{{ text }}</span>
    </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { SongStatus } from "@common/song-status";
import { Song } from "../../../models/song/song";
import { api } from "../../../apis/api";
import { shouldHideAction } from "../../../utils/song-actions-utils";
import { SongActionOptions } from "../../../interfaces/song-actions";

export default defineComponent({
    name: "song-action-pin",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        songActionOptions: {
            type: Object as PropType<SongActionOptions>,
            default: () => ({}),
        },
        flat: {
            type: Boolean,
            default: false,
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            actionInProgress: false,
        };
    },
    computed: {
        hide(): boolean {
            return shouldHideAction("pin", this.song, this.songActionOptions);
        },
        disabled(): boolean {
            return this.actionInProgress || this.songActionOptions.disabled === true;
        },
        isPinned(): boolean {
            return this.song.isPinned();
        },
        text(): string {
            return this.isPinned ? this.$t("actionUnpin") : this.$t("actionPin");
        },
        tooltip(): string {
            return this.text;
        },
    },
    methods: {
        async onTogglePin(): Promise<void> {
            if (this.disabled) {
                return;
            }

            api.log.track("togglePin");
            const updatedSong = this.song.copy();
            updatedSong.status = this.song.isPinned() ? SongStatus.Default : SongStatus.Pinned;

            this.actionInProgress = true;
            await this.$store.song.update(updatedSong);
            this.actionInProgress = false;
        },
    },
});
</script>
