<template>
    <g :transform="transform">
        <path d="M-5 -10 L0 0 L5 -10" stroke-width="1" />
    </g>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Point } from "../../../../models/point";
import { Geometry } from "../../../../services/renderer/geometry";

export default defineComponent({
    name: "upstroke",
    props: {
        from: {
            type: Point as PropType<Point>,
            required: true,
        },
        geometry: {
            type: Geometry,
            required: true,
        },
    },
    computed: {
        transform(): string {
            const offset = new Point(0, this.geometry.getRowHeight() + 19);

            const originPoint = this.from.plus(offset);
            return `translate(${originPoint.x}, ${originPoint.y})`;
        },
    },
});
</script>

<style lang="scss" scoped>
path {
    stroke: var(--text-color);
    fill: none;
}
</style>
