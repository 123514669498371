<template>
    <app-modal @close="onCloseMoveModal">
        <template v-slot:header>{{ $t("moveTitle", { song: songName }) }}</template>
        <template v-slot:body>
            <div class="move-modal-container">
                <div class="move-information-container">
                    <div class="move-information-block song-name-block">
                        <div class="move-information-label song-name-label">{{ $t("moveMovingSong") }}:</div>
                        <div class="move-information-value song-name">{{ songName }}</div>
                    </div>
                    <div class="move-information-block current-notebook-block">
                        <div class="move-information-label current-nobetook-label">
                            {{ $t("moveCurrentLocation") }}:
                        </div>
                        <div v-if="isAtNotebook" class="move-information-value current-notebook">
                            <i class="bi bi-journal-bookmark-fill"></i>
                            {{ songNotebook }}
                        </div>
                        <div v-else class="move-information-value my-songs">
                            <i class="bi bi-music-note"></i>
                            {{ $t("linkSongs") }}
                        </div>
                    </div>
                </div>

                <div class="custom-notebooks-container">
                    <input
                        autofocus
                        type="text"
                        class="form-control search-notebook"
                        :maxlength="maxLabelLength"
                        v-model="searchValue"
                        :placeholder="$t('moveSearchNotebookPlaceholder')"
                        @keydown.enter="onInput" />
                    <div id="emailHelp" class="form-text">{{ $t("moveTypeNewNotebookNameToCreate") }}</div>

                    <div class="custom-notebooks-list">
                        <div v-if="showNoNotebooksMessage" class="custom-notebooks-result">
                            <i class="bi bi-music-note"></i>
                            {{ $t("moveNoCustomNotebooks") }}
                        </div>

                        <div v-if="showNoResultsMessage" class="custom-notebooks-result">
                            <i class="bi bi-search"></i>
                            {{ $t("moveNoNotebooksFound") }}
                        </div>

                        <button
                            v-for="notebook in filteredNotebooks"
                            class="btn custom-notebook"
                            :class="{ disabled: notebook === songNotebook }"
                            @click="moveTo(notebook)"
                            @keydown.enter="moveTo(notebook)"
                            tabindex="0">
                            <i class="bi bi-journal-bookmark-fill"></i>
                            {{ notebook }}
                        </button>
                    </div>
                    <button
                        class="btn btn-secondary create"
                        :class="{ disabled: !canCreateNotebook }"
                        @click="moveToNewNotebook(searchValue)"
                        @keydown.enter="moveToNewNotebook(searchValue)"
                        tabindex="0">
                        {{ createNotebookText }}
                    </button>
                </div>

                <template v-if="canMoveToSongs">
                    <button
                        class="btn btn-primary"
                        @click="moveTo(undefined)"
                        @keydown.enter="moveTo(undefined)"
                        tabindex="0">
                        {{ $t("actionMoveToSongs") }}
                    </button>
                </template>
            </div>
        </template>
    </app-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { MAX_LABEL_LENGTH, MAX_NOTEBOOKS } from "@common/constants";
import { isWhitespaceString } from "../../../utils/utils";
import { api } from "../../../apis/api";
import { Song } from "../../../models/song/song";
import AppModal from "@components/app-modal/app-modal.vue";

export default defineComponent({
    name: "song-action-move-modal",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    emits: {
        close: () => true,
    },
    components: {
        "app-modal": AppModal,
    },
    data() {
        return {
            searchValue: "",
        };
    },
    computed: {
        createNotebookText(): string {
            if (this.canCreateNotebook) {
                return this.$t("moveCreateANotebook", { notebook: this.searchValue });
            }

            return this.$t("moveCreateNotebook");
        },
        showNoNotebooksMessage(): boolean {
            return this.notebooks.length === 0 && this.searchValue.trim().length === 0;
        },
        showNoResultsMessage(): boolean {
            return this.filteredNotebooks.length === 0 && this.searchValue.trim().length > 0;
        },
        songName(): string {
            return isWhitespaceString(this.song.title) ? this.$t("songUntitled") : this.song.title;
        },
        maxLabelLength(): number {
            return MAX_LABEL_LENGTH;
        },
        isAtNotebook(): boolean {
            return (this.song.metadata.labels ?? []).length > 0;
        },
        songNotebook(): string | undefined {
            return this.song.metadata.labels?.[0];
        },
        notebooks(): string[] {
            return this.$store.config.userNotebooks.sort((a, b) => a.localeCompare(b));
        },
        filteredNotebooks(): string[] {
            return this.notebooks.filter((notebook) => {
                if (this.searchValue !== "") {
                    return notebook.toLowerCase().includes(this.searchValue.toLowerCase());
                }

                return true;
            });
        },
        canCreateNotebook(): boolean {
            if (this.searchValue.trim().length === 0) {
                return false;
            }

            return !this.$store.config.userNotebooks.some((notebook) => {
                return notebook.toLowerCase() === this.searchValue.toLowerCase();
            });
        },
        canMoveToSongs(): boolean {
            return this.isAtNotebook;
        },
        notebookCount(): number {
            return this.$store.config.userNotebooks.length;
        },
    },
    methods: {
        onCloseMoveModal(): void {
            this.$emit("close");
        },
        onInput(): void {
            if (this.searchValue.trim().length === 0) {
                return;
            }

            const foundNotebook = this.$store.config.userNotebooks.find((notebook) => {
                return notebook.toLowerCase() === this.searchValue.toLowerCase();
            });

            if (foundNotebook) {
                this.moveTo(foundNotebook);
            } else {
                this.moveToNewNotebook(this.searchValue);
            }
        },
        moveToNewNotebook(notebook: string): void {
            if (this.notebookCount >= MAX_NOTEBOOKS) {
                this.$bus.emit("error", this.$t("notebookLimitError"));
            } else {
                this.moveTo(notebook);
            }
        },
        async moveTo(notebook: string | undefined): Promise<void> {
            const updatedSong = this.song.copy();
            let messageKey: string;
            if (notebook) {
                api.log.track("moveToNotebook");
                updatedSong.metadata.labels = [notebook];
                messageKey = "actionCompleteMoveToNotebook";
            } else {
                updatedSong.metadata.labels = [];
                messageKey = "actionCompleteMoveToSongs";
            }
            await this.$store.song.update(updatedSong);

            this.$bus.emit("info", this.$t(messageKey, { songName: this.songName, notebook: notebook }));
        },
    },
});
</script>

<style lang="scss" scoped>
.move-modal-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .move-information-container {
        background-color: var(--gray-50);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 5px 20px;

        .move-information-block {
            display: flex;
            gap: 15px;

            .move-information-label {
                text-wrap: nowrap;
            }

            .move-information-value {
                text-wrap: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .custom-notebooks-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        min-height: 350px;
        max-height: 350px;

        .form-text {
            color: var(--text-color-light);
            margin-top: -5px;
        }

        .custom-notebooks-list {
            background-color: var(--gray-50);
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow-y: auto;
            margin: 5px 0;

            .btn {
                justify-content: flex-start;
            }
        }

        .create {
            display: block;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            min-height: 40px;
        }
    }

    .custom-notebooks-result {
        align-items: center;
        color: var(--text-color-light);
        display: flex;
        flex-direction: row;
        font-size: 14px;
        gap: 10px;
        padding: 0px 15px;
        justify-content: center;
        white-space: normal;
        margin-top: 80px;
    }
}
</style>
