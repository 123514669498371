<template>
    <div class="preview">
        <tablature :song="song" :tablature="song.tablature" :toEvent="toEvent" :style="style" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Tablature from "@components/tablature/tablature.vue";
import { Song } from "../../models/song/song";
import { TABLATURE_PREVIEW_SIZE } from "../../../common/constants";
import { DEFAULT_TABLATURE_STYLE } from "../../services/renderer/tablature-style";

export default defineComponent({
    name: "preview",
    props: {
        song: {
            type: Song,
            required: true,
        },
    },
    data() {
        return {
            style: { ...DEFAULT_TABLATURE_STYLE, paddingBottom: 30 },
        };
    },
    components: {
        tablature: Tablature,
    },
    computed: {
        toEvent() {
            return TABLATURE_PREVIEW_SIZE;
        },
    },
});
</script>

<style lang="scss" scoped>
.preview {
    background-color: var(--background-color-secondary);
    display: flex;
    flex-wrap: wrap;
    height: 160px;
    overflow: hidden;
}
</style>
