import { Point } from "../models/point";

export class SVGHandler {
    private svg: SVGGraphicsElement;

    constructor(svg: SVGGraphicsElement) {
        this.svg = svg;
    }

    private get ctm(): DOMMatrix {
        const ctm = this.svg.getScreenCTM();
        if (!ctm) throw new Error("Fatal error, could not get screen CTM from svg");
        return ctm;
    }

    /** Converts a global point to a point in the svg */
    public toSVGPoint(x: number, y: number): Point {
        const p = new DOMPoint(x, y);

        const point = p.matrixTransform(this.ctm.inverse());
        return this.DOMPoint2Point(point);
    }

    /** Converts an svg point to a global point */
    public fromSVGPoint(point: Point): Point {
        const p = new DOMPoint(point.x, point.y);

        const newPoint = p.matrixTransform(this.ctm);
        return this.DOMPoint2Point(newPoint);
    }

    public setSvgAttributes(attrs: Record<string, string>) {
        for (const [key, value] of Object.entries(attrs)) {
            this.svg.setAttribute(key, value);
        }
    }

    private DOMPoint2Point(p: DOMPoint): Point {
        return new Point(p.x, p.y);
    }
}
