import { DEFAULT_INSTRUMENT } from "../../../common/constants";
import type { SongDTO } from "../../../common/dtos/song.dto";
import type { SongTags } from "../../../common/song-tags";
import { Song } from "./song";

export type ParserInput = Omit<SongDTO, "version">;

export function parseSongDTO(id: string, originalSong: ParserInput): Song {
    // Any parsing regarding multiple versions must be done here

    originalSong.metadata.visibility = originalSong.metadata.visibility ?? "private";
    originalSong.metadata.labels = originalSong.metadata.labels ?? [];

    originalSong.tags = parseTags(originalSong.tags);

    return new Song({
        ...originalSong,
        id,
    }).sanitize();
}

function parseTags(originalTags: SongTags): SongTags {
    if (!originalTags.instrument) {
        originalTags.instrument = DEFAULT_INSTRUMENT;
    }

    return originalTags;
}
