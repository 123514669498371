import type { NoteDTO } from "@common/note";
import type { TablatureEvent } from "@common/tablature-event";
import { isInArray } from "../utils/is-in-array";

export interface ClipboardData {
    type: "note" | "events";
}

export function isClipboardData(object: unknown): object is ClipboardData {
    if (object && typeof object === "object") {
        return "type" in object && isInArray(["note", "events"], object.type);
    }
    return false;
}

export interface ClipboardNote extends ClipboardData {
    note?: NoteDTO;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isClipboardNote(object: any): object is ClipboardNote {
    return isClipboardData(object) && object.type === "note" && "note" in object;
}

export interface ClipboardEvents extends ClipboardData {
    events: Array<TablatureEvent | undefined>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isClipboardEvents(object: any): object is ClipboardEvents {
    return isClipboardData(object) && object.type === "events" && "events" in object;
}
