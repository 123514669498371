export type APIErrorName = "song-not-found" | "offline" | "user-not-logged-in";

export class ApiError extends Error {
    public readonly name: APIErrorName;

    constructor(name: APIErrorName, message: string) {
        super(`Api Error: ${name}. ${message}`);
        this.name = name;
    }
}

export function isApiError(error: unknown): error is ApiError {
    return error instanceof ApiError;
}
