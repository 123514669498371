<template>
    <form class="row g-3" @click.prevent>
        <!-- <div class="form-group col-12" v-if="canUpdatePassword">
            <button @click="updatePassword" class="btn">Update Password</button>
        </div> -->

        <legend>{{ $t("settingsAccount") }}</legend>

        <template v-if="userLoggedIn">
            <div class="form-group col-12">
                <label for="logout" class="form-label">{{ $t("loggedInAs", user?.email) }}:</label>
                <button @click="logout" id="logout" class="btn btn-gray">{{ $t("logOut") }}</button>
            </div>
            <div class="form-group col-12">
                <hr class="border-2 border-top border-danger" />
            </div>
            <div class="form-group col-12">
                <button @click="deleteAccount" class="btn btn-danger">{{ $t("deleteAccount") }}</button>
            </div>
        </template>

        <template v-else>
            <div class="form-group col-12">
                <label for="login" class="form-label">{{ $t("notLoggedIn") }}:</label>
                <button @click="login" id="login" class="btn btn-gray">{{ $t("logIn") }}</button>
            </div>
            <div class="form-group col-12">
                <hr class="border-2 border-top border-danger" />
            </div>
            <div class="form-group col-12">
                <button @click="clearData" class="btn btn-danger">{{ $t("clearData") }}</button>
            </div>
        </template>
    </form>
</template>

<script lang="ts">
import { User } from "firebase/auth";
import { defineComponent } from "vue";
import { api } from "../../../apis/api";
import { FirebaseError } from "@firebase/app";
import Swal from "sweetalert2";

export default defineComponent({
    name: "settings-account",
    data() {
        return {
            selectedLanguage: this.$i18n.locale,
        };
    },
    computed: {
        user(): User | undefined {
            return this.$store.user.user;
        },
        userLoggedIn(): boolean {
            return this.$store.user.loggedIn;
        },
        canUpdatePassword(): boolean {
            return this.$store.user.authProvider === "email";
        },
    },
    methods: {
        changeLanguage() {
            this.$i18n.locale = this.selectedLanguage;
            api.localStorage.set("userLocale", this.selectedLanguage);
        },
        async deleteAccount(): Promise<void> {
            const result = await Swal.fire({
                title: this.$t("actionDeleteAccountConfirmationTitle"),
                text: this.$t("actionCannotBeUndone"),
                showCancelButton: true,
                cancelButtonText: this.$t("actionCancel"),
                confirmButtonText: this.$t("actionDelete"),
                customClass: {
                    confirmButton: "btn-danger",
                },
                icon: "warning",
            });
            if (result.isConfirmed) {
                try {
                    if (!api.auth.isIOSApp) {
                        await api.auth.reauthenticate();
                    }
                    await api.auth.deleteAccount();
                    await this.$store.user.logout();
                    localStorage.clear();
                    await this.$router.push({ name: "dashboard" });
                } catch (error) {
                    // Delete Account may fail if the user didn't authenticate
                    // recently, ask for reauthentication before trying again

                    if (error instanceof FirebaseError) {
                        switch (error.code) {
                            // TODO: properly handle these errors
                            case "auth/popup-closed-by-user":
                                this.$bus.emit(
                                    "error",
                                    `Error Deleting Account (popup closed): Reauthenticate to delete the account`
                                );
                                api.log.error(error);
                                return;
                        }
                    }

                    this.$bus.emit("error", `Error Deleting Account: ${error}`);
                    throw error;
                }
            }
        },
        // async updatePassword(): Promise<void> {
        //     await Swal.fire({
        //         title: "Update Password",
        //         input: "password",
        //         inputPlaceholder: "Enter your new password",
        //         showCancelButton: true,
        //         confirmButtonText: "Update Password",
        //         showLoaderOnConfirm: true,
        //         allowOutsideClick: () => !Swal.isLoading(),
        //         preConfirm(pwd: string) {
        //             return api.auth.updatePassword(pwd).catch((error) => {
        //                 Swal.showValidationMessage(error);
        //             });
        //         },
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Swal.fire({
        //                 title: `Password Updated`,
        //             });
        //         }
        //     });
        // },
        async logout(): Promise<void> {
            await this.$store.user.logout();
            await this.$router.push({ name: "dashboard" });
        },
        async login(): Promise<void> {
            await this.$router.push({
                name: "signin",
            });
        },
        async clearData(): Promise<void> {
            const result = await Swal.fire({
                title: this.$t("actionClearDataConfirmationTitle"),
                text: this.$t("actionCannotBeUndone"),
                showCancelButton: true,
                cancelButtonText: this.$t("actionCancel"),
                confirmButtonText: this.$t("actionClearData"),
                customClass: {
                    confirmButton: "btn-danger",
                },
                icon: "warning",
            });
            if (result.isConfirmed) {
                await api.syncApi.onLogout();
                localStorage.clear();
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.btn {
    width: 100%;
}

legend {
    margin-top: 0;
}
</style>
