<template>
    <div
        class="offcanvas offcanvas-start offcanvas-sidebar"
        tabindex="-1"
        id="offcanvas-sidebar"
        aria-labelledby="offcanvas-sidebar-label">
        <div class="offcanvas-header">
            <app-name id="offcanvas-sidebar-label" :hideDescription="true"></app-name>
        </div>
        <div class="offcanvas-body">
            <sidebar-content></sidebar-content>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SidebarContent from "./sidebar-content.vue";
import AppName from "../app-name/app-name.vue";

export default defineComponent({
    name: "sidebar-offcanvas",
    components: {
        "app-name": AppName,
        "sidebar-content": SidebarContent,
    },
});
</script>

<style lang="scss" scoped>
.offcanvas-sidebar {
    max-width: 80%;

    .offcanvas-header {
        background-color: var(--background-color-secondary);
        border-bottom: solid 1px var(--gray-300);
        height: 62px;
    }

    .offcanvas-body {
        background-color: var(--background-color-primary);
        padding-left: 0;
        padding-right: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}
</style>
