<template>
    <li
        v-if="!hide"
        class="btn"
        :class="{ disabled }"
        :title="tooltip"
        @click.prevent="onCopy"
        @keydown.enter.prevent="onCopy"
        tabindex="0">
        <i class="bi bi-copy"></i>
        <span v-if="!hideText">{{ text }}</span>
    </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Song } from "../../../models/song/song";
import { api } from "../../../apis/api";
import { shouldHideAction } from "../../../utils/song-actions-utils";
import { SongActionOptions } from "../../../interfaces/song-actions";

export default defineComponent({
    name: "song-action-copy",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        songActionOptions: {
            type: Object as PropType<SongActionOptions>,
            default: () => ({}),
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            actionInProgress: false,
        };
    },
    computed: {
        hide(): boolean {
            return shouldHideAction("copy", this.song, this.songActionOptions);
        },
        disabled(): boolean {
            return this.actionInProgress || this.songActionOptions.disabled === true;
        },
        text(): string {
            return this.$t("actionCopy");
        },
        tooltip(): string {
            return this.text;
        },
    },
    methods: {
        async onCopy(): Promise<void> {
            if (this.disabled) {
                return;
            }

            api.log.track("copySong");
            const copiedSong = this.song.copy();
            copiedSong.title += this.$t("copiedSongNameAppend");

            this.actionInProgress = true;
            await this.$store.editor.copySong(copiedSong);
            this.$store.song.fetchSongs();

            this.actionInProgress = false;
        },
    },
});
</script>
