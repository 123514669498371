import { Base64 } from "js-base64";
import type { TablaturePosition } from "../../../../interfaces/tablature-position";
import { getClipboardStore } from "../../../../stores/clipboard.store";
import { nullToUndefined } from "../../../../utils/null-to-undefined";
import { EditorCommand } from "../editor-command";
import { deleteSelectionRange } from "../utils/delete-selection-range";
import type { ClipboardData } from "../../../../interfaces/clipboard";
import { isClipboardData, isClipboardEvents, isClipboardNote } from "../../../../interfaces/clipboard";
import { bus } from "../../../../bus";

export class PasteCommand extends EditorCommand {
    private pasteCache: ClipboardData | undefined;

    public async onRedo(): Promise<void> {
        if (!this.pasteCache) {
            return;
        }
        deleteSelectionRange(this.tablatureEditor, this.editorSelection);
        const selectionPosition = this.editorSelection.getPosition();
        if (!selectionPosition) {
            return;
        }
        await this.handleClipboardData(selectionPosition, this.pasteCache);
    }

    public async onExecute(): Promise<void> {
        const data = await this.getClipboardJSON();
        if (!data || !isClipboardData(data)) {
            return;
        }
        this.pasteCache = data;
        deleteSelectionRange(this.tablatureEditor, this.editorSelection);
        const selectionPosition = this.editorSelection.getPosition();
        if (!selectionPosition) {
            return;
        }
        await this.handleClipboardData(selectionPosition, data);
    }

    private async handleClipboardData(selectionPosition: TablaturePosition, data: ClipboardData): Promise<void> {
        if (isClipboardNote(data)) {
            this.tablatureEditor.updateNoteFret(selectionPosition, data.note?.fret);
            if (data.note?.modifiers) {
                this.tablatureEditor.updateNoteModifiers(selectionPosition, data.note.modifiers);
            }

            if (this.isAtLastString()) {
                this.tablatureEditor.insertEventsAfter(selectionPosition.eventIndex, undefined);
            }

            this.editorSelection.cursor.next();
            return;
        }

        if (isClipboardEvents(data)) {
            this.tablatureEditor.insertEventsAfter(selectionPosition.eventIndex - 1, data.events);
            this.editorSelection.cursor.nextChord(data.events.length);
            return;
        }
    }

    private isAtLastString(): boolean {
        if (this.editorSelection.hasRange()) {
            return false;
        }
        const selectionPosition = this.editorSelection.getPosition();
        if (!selectionPosition) return false;
        return selectionPosition.stringIndex === this.tablature.lineCount - 1;
    }

    private async getClipboardJSON(): Promise<unknown> {
        try {
            const clipboardStore = getClipboardStore();

            const clipboardContent = await clipboardStore.readText();
            if (!clipboardContent) {
                return;
            }
            return this.parseClipboardText(clipboardContent);
        } catch (_err) {
            bus.emit("error", "Cannot Paste: Invalid data in clipboard");
            return;
        }
    }

    private parseClipboardText(base64Text: string): unknown {
        const jsonStr = Base64.decode(base64Text);
        const jsonData = JSON.parse(jsonStr);
        return nullToUndefined(jsonData);
    }
}
