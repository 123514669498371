import { defineStore } from "pinia";
import { isMobileUser } from "../utils/is-mobile-user";

export interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;
    prompt(): Promise<void>;
}

export const getInstallStore = defineStore("install", {
    state() {
        return {
            deferredPrompt: undefined as BeforeInstallPromptEvent | undefined,
        };
    },
    actions: {
        shouldShow(): boolean {
            return this.deferredPrompt !== undefined && isMobileUser();
        },
        prompt(): void {
            if (!this.deferredPrompt) {
                return;
            }

            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    this.deferredPrompt = undefined;
                }
            });
        },
    },
});

export type InstallStore = ReturnType<typeof getInstallStore>;
