import type { ObjectDirective } from "vue";

interface ExtendedHTMLElement extends HTMLElement {
    additionalData: {
        lastScrollTop: number;
        scrollingContainerSelector: string;
    };
}

type GenericEventHandler = () => void;
interface ExtendedDirective extends ObjectDirective {
    scrollEvent: GenericEventHandler;
}

function onScroll(el: ExtendedHTMLElement): void {
    if (!el) {
        return;
    }

    const data = el.additionalData;
    const scrollingContainer = document.querySelector(data.scrollingContainerSelector);
    if (!scrollingContainer) {
        return;
    }
    const currentScrollTop = scrollingContainer.scrollTop;
    const isScrollingDown = currentScrollTop > 0 && currentScrollTop > data.lastScrollTop;
    el.style.transform = isScrollingDown ? "translateY(-100%)" : "translateY(0)";
    el.style.transition = isScrollingDown ? "all 0.1s ease-in-out" : "all 0.2s ease-in-out";

    // Instead of setting the opacity to 1, we remove the inline style to let the CSS take over again.
    // For example, a disabled button might have an opacity of 0.5 in the CSS.
    const removeCSSPropertyValue = null as unknown as string; // Typescript thinks that 'null' is not assignable.
    el.style.opacity = isScrollingDown ? "0" : removeCSSPropertyValue;

    data.lastScrollTop = currentScrollTop;
}

export const hideOnScrollDirective: ObjectDirective<ExtendedHTMLElement, string> = {
    mounted(el: ExtendedHTMLElement, binding, _vnode, _prevVnode): void {
        el.additionalData = {
            lastScrollTop: 0,
            scrollingContainerSelector: binding.value,
        };

        const data = el.additionalData;
        const thisDirective = binding.dir as ExtendedDirective;

        const scrollingContainer = document.querySelector(data.scrollingContainerSelector);
        if (!scrollingContainer) {
            return;
        }

        thisDirective.scrollEvent = () => {
            onScroll(el);
        };
        scrollingContainer.addEventListener("scroll", thisDirective.scrollEvent);
    },
    beforeUnmount(el, binding): void {
        const data = el.additionalData;
        const thisDirective = binding.dir as ExtendedDirective;
        const scrollingContainer = document.querySelector(data.scrollingContainerSelector);
        if (!scrollingContainer) {
            return;
        }
        scrollingContainer.removeEventListener("scroll", thisDirective.scrollEvent);
    },
};
