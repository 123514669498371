<template>
    <div class="tuning-container" @focusout="clearSelection" ref="tuningContainer" tabindex="0">
        <!-- loop index is 1-based -->
        <button
            v-for="string in stringCount"
            class="string-selector"
            :class="{ selected: selectedString === string - 1 }"
            @click="clickTuning(string - 1)">
            {{ tuning[string - 1] }}
        </button>

        <string-tuning-selector
            v-show="canEdit && selectedString !== undefined"
            :style="{ top: tuningSelectorTopStyle }"
            :selected-string="selectedString"
            :selected-tuning="selectedTuning"
            @selected-tuning="updateTuning" />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import StringTuningSelector from "./string-tuning-selector.vue";
import { TuningOption } from "@common/types";

export default defineComponent({
    name: "tuning",
    props: {
        lineHeight: {
            type: Number,
            required: true,
        },
        tuning: {
            type: Array as PropType<Array<TuningOption>>,
            required: true,
        },
        stringCount: {
            type: Number,
            required: true,
        },
        canEdit: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            selectedString: undefined as number | undefined,
        };
    },
    emits: {
        tuningUpdate: (_tuning: TuningOption[]) => true,
    },
    components: {
        "string-tuning-selector": StringTuningSelector,
    },
    computed: {
        lineHeightStr(): string {
            return `${this.lineHeight}px`;
        },
        fontSize(): string {
            const fontHeight = this.lineHeight - 2;
            return `${fontHeight}px`;
        },
        tuningSelectorTopStyle(): string {
            if (this.selectedString === undefined) return "";
            const tunningSelectorPostion = this.selectedString * this.lineHeight;
            const tunningSelectorCenter = this.lineHeight / 2;
            return `${tunningSelectorPostion + tunningSelectorCenter}px`;
        },
        selectedTuning(): TuningOption | undefined {
            if (this.selectedString === undefined) return undefined;
            return this.tuning[this.selectedString];
        },
        tuningContainer(): HTMLElement {
            return this.$refs.tuningContainer as any;
        },
        stringLabelCursor(): "pointer" | "auto" {
            return this.canEdit ? "pointer" : "auto";
        },
        hoverBackgroundColor(): string {
            return this.canEdit ? "var(--primary-color-200)" : "transparent";
        },
    },
    methods: {
        clickTuning(index: number) {
            if (this.canEdit) {
                this.selectedString = index;
            }
        },
        clearSelection(ev: FocusEvent): void {
            const relatedTarget = ev.relatedTarget;
            const targetIsChildren = relatedTarget && this.tuningContainer.contains(relatedTarget as any);
            if (targetIsChildren) {
                ev.preventDefault();
            } else {
                this.selectedString = undefined;
            }
        },
        updateTuning(selectedString: number, option: TuningOption): void {
            this.tuning[selectedString] = option;
            this.$emit("tuningUpdate", this.tuning);
        },
    },
});
</script>

<style lang="scss" scoped>
.tuning-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-size: v-bind("fontSize");
    line-height: v-bind("lineHeightStr");
    user-select: none;

    .string-selector {
        background: none;
        border: none;
        border-radius: v-bind("lineHeightStr");
        color: inherit;
        cursor: v-bind("stringLabelCursor");
        font: inherit;
        height: v-bind("lineHeightStr");
        outline: inherit;
        padding: 0;
        width: v-bind("lineHeightStr");

        &:hover {
            background-color: v-bind("hoverBackgroundColor");
        }

        &.selected {
            background-color: var(--primary-color-200);
        }
    }
}
</style>
