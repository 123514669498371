import { clamp } from "../utils/utils";

/** A readonly constant point */
export class Point {
    public readonly x: number;
    public readonly y: number;

    static ZERO = new Point(0, 0);

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    public minus(point?: Point): Point {
        if (!point) return new Point(-this.x, -this.y);
        return new Point(this.x - point.x, this.y - point.y);
    }

    public half(): Point {
        return new Point(this.x / 2, this.y / 2);
    }

    public div(point: Point): Point;
    public div(number: number): Point;
    public div(point: Point | number): Point {
        if (typeof point === "number") point = new Point(point, point);
        return new Point(this.x / point.x, this.y / point.y);
    }

    public plus(point: Point): Point {
        return new Point(this.x + point.x, this.y + point.y);
    }

    public round(): Point {
        return new Point(Math.round(this.x), Math.round(this.y));
    }

    public abs(): Point {
        return new Point(Math.abs(this.x), Math.abs(this.y));
    }

    public clamp(min: Point | undefined, max?: Point): Point {
        const clampedX = clamp(this.x, min?.x, max?.x);
        const clampedY = clamp(this.y, min?.y, max?.y);
        return new Point(clampedX, clampedY);
    }
}
