import Joi from "joi";
import { MAX_LABEL_LENGTH, MAX_STRINGS, SONG_INSTRUMENTS, TABLATURE_MAX_EVENTS, TUNING_OPTIONS } from "../constants";
import type { SongDTO } from "../dtos/song.dto";
import { SongStatus } from "../song-status";
import { colorValidator, defaultIntValidator, defaultStringValidator } from "./default-validators";
import { tablatureEventValidator } from "./event-validator";

const tagsValidator = Joi.object({
    artist: defaultStringValidator.allow(""),
    duration: defaultIntValidator,
    color: colorValidator,
    comment: defaultStringValidator.allow(""),
    instrument: Joi.string().valid(...SONG_INSTRUMENTS),
});

const tablatureValidator = Joi.object({
    events: Joi.array()
        .items(...tablatureEventValidator, null)
        .required()
        .sparse(true)
        .max(TABLATURE_MAX_EVENTS),
    stave: Joi.object({
        lineCount: defaultIntValidator.positive().max(MAX_STRINGS).required(),
    }).required(),
});

const songStatusValidator = Joi.string().valid(...Object.values(SongStatus));
const tuningValidator = Joi.array()
    .items(...TUNING_OPTIONS)
    .min(1)
    .max(MAX_STRINGS);

const songTitleValidator = defaultStringValidator.allow("");

const visibilityValidator = Joi.string().valid("public", "private");
const labelsValidator = Joi.array().items(defaultStringValidator.max(MAX_LABEL_LENGTH));

// This only validates metadata input
// NOTE: The validator takes all keys as optional
const metadataValidator = Joi.object({
    visibility: visibilityValidator,
    lastEditTime: defaultIntValidator,
    createdAt: defaultIntValidator,
    labels: labelsValidator.optional(),
});

const songInputValidator = Joi.object({
    title: songTitleValidator,
    tags: tagsValidator,
    tablature: tablatureValidator,
    status: songStatusValidator,
    tuning: tuningValidator,
    metadata: metadataValidator,
    version: Joi.string(),
}).required();

// NOTE: the DTO is actually incorrect, as the undefined values are null due to JSON shenanigans
export async function validateSongInput(value: unknown): Promise<Partial<SongDTO>> {
    return await songInputValidator.validateAsync(value);
}

export function validateSongId(id: unknown): Promise<string> {
    const idValidator = Joi.string().uuid().required();
    return idValidator.validateAsync(id);
}

export function validateVisibility(value: unknown): Promise<"public" | "private"> {
    return visibilityValidator.required().validateAsync(value) as Promise<"public" | "private">;
}
