import { SongStatus } from "@common/song-status";
import Joi from "joi";
import { defineStore } from "pinia";
import { LoadingStatus, MAX_SONGS, SyncStatus } from "../../common/constants";
import type { SongVisibility } from "../../common/song-metadata";
import { api } from "../apis/api";
import type { Song } from "../models/song/song";
import { getConfigStore } from "./config.store";
import { getEditorStore } from "./editor.store";

export const getSongStore = defineStore("song", {
    state() {
        return {
            songs: [] as Song[],
            loadStatus: LoadingStatus.UNINITIALIZED,
            syncStatus: SyncStatus.NOT_SYNCED,
        };
    },
    getters: {
        find(state) {
            return (filter: { status: SongStatus; text?: string; notebook?: string }): Song[] => {
                const ignoreNotebookFilter = [SongStatus.Archived, SongStatus.Trashed].includes(filter.status);

                return (state.songs as Song[])
                    .filter((s) => {
                        const matchesStatus = s.status === filter.status;
                        if (!matchesStatus) {
                            return false;
                        }

                        const matchesText =
                            filter.text === undefined || s.title.toLowerCase().includes(filter.text.toLowerCase());
                        if (!matchesText) {
                            return false;
                        }

                        if (ignoreNotebookFilter) {
                            return true;
                        }

                        const labels = s.metadata.labels ?? [];
                        return (
                            (filter.notebook === undefined && labels.length === 0) ||
                            (filter.notebook !== undefined && labels.includes(filter.notebook))
                        );
                    })
                    .sort((a, b) => b.metadata.lastEditTime - a.metadata.lastEditTime);
            };
        },
        getById(state) {
            return (id: string): Song | undefined => {
                return (state.songs as Song[]).find((s) => s.id === id) as Song | undefined;
            };
        },
        loaded(state): boolean {
            return state.loadStatus === LoadingStatus.READY;
        },
        canAddSong(): boolean {
            return this.songs.length < MAX_SONGS;
        },
    },
    actions: {
        async update(song: Song): Promise<void> {
            try {
                await api.songs.setSong(song.id, song.toDTO());
                const updatedSong = song;

                const index = this.songs.findIndex((s) => s.id === song.id);
                if (index > -1) {
                    this.songs.splice(index, 1, updatedSong);
                } else {
                    this.songs.push(updatedSong);
                }

                const editorStore = getEditorStore();
                // Updates editor status if needed
                if (editorStore.selectedSongId === song.id) {
                    editorStore.updateStatus(updatedSong.status);
                }
            } catch (error: unknown) {
                console.error(error);
                if (error instanceof Joi.ValidationError) {
                    this.$bus.emit("error", `Song could not be saved: ${error.message}`);
                } else {
                    this.$bus.emit("error", "Song could not be saved");
                }
            }

            await this.fetchSongs();
        },
        async updateVisibility(songId: string, visibility: SongVisibility): Promise<void> {
            await api.syncApi.awaitSync();

            await api.songs.updateVisibility(songId, visibility);
            await this.fetchSongs();
        },
        async delete(song: Song): Promise<void> {
            try {
                await api.songs.delete(song.id);

                const index = this.songs.findIndex((s) => s.id === song.id);
                if (index > -1) {
                    this.songs.splice(index, 1);
                }
            } catch (error: unknown) {
                console.error(error);
                this.$bus.emit("error", "Song could not be deleted");
            }

            await this.fetchSongs();
        },
        async fetchSongs(): Promise<void> {
            this.loadStatus =
                this.loadStatus === LoadingStatus.UNINITIALIZED ? LoadingStatus.LOADING : LoadingStatus.RELOADING;

            try {
                this.songs = await api.songs.getAll();

                const configStore = getConfigStore();
                configStore.onSongsUpdate(this.songs as Song[]);

                this.loadStatus = LoadingStatus.READY;
            } catch (error: unknown) {
                console.error(error);
                this.$bus.emit("error", "Songs could not be loaded");
                this.loadStatus = LoadingStatus.ERROR;
            }
        },
    },
});

export type SongStore = ReturnType<typeof getSongStore>;
