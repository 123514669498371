import type { TablaturePosition } from "./tablature-position";
import { isTablaturePosition } from "./tablature-position";

export interface TablatureRange {
    from: TablaturePosition;
    to: TablaturePosition;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTablatureRange(object: any): object is TablatureRange {
    return (
        object !== undefined &&
        "from" in object &&
        isTablaturePosition(object.from) &&
        "to" in object &&
        isTablaturePosition(object.to)
    );
}
