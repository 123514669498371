<template>
    <form class="row g-3" @click.prevent>
        <legend>{{ $t("settingsUserPreferences") }}</legend>
        <div class="form-group col-12">
            <label for="languageSelector" class="form-label">{{ $t("settingsSelectLanguage") }}:</label>
            <select
                class="form-select form-select-lg"
                id="languageSelector"
                v-model="selectedLanguage"
                @change="changeLanguage">
                <option value="id">Bahasa Indonesia</option>
                <option value="de">Deutsch</option>
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fil">Filipino</option>
                <option value="fr">Français</option>
                <option value="it">Italiano</option>
                <option value="nl">Nederlands</option>
                <option value="pl">Polski</option>
                <option value="pt">Português</option>
                <option value="ru">Русский</option>
                <option value="sv">Svenska</option>
                <option value="tr">Türkçe</option>
                <option value="zh">中文</option>
                <option value="ja">日本語</option>
                <option value="ko">한국어</option>
                <option value="hi">हिन्दी</option>
            </select>
        </div>
    </form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { api } from "../../../apis/api";

export default defineComponent({
    name: "settings-user-preferences",
    data() {
        return {
            selectedLanguage: this.$i18n.locale,
        };
    },
    methods: {
        changeLanguage() {
            this.$i18n.locale = this.selectedLanguage;
            api.localStorage.set("userLocale", this.selectedLanguage);
        },
    },
});
</script>

<style lang="scss" scoped>
legend {
    margin-top: 0;
}
</style>
