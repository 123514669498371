import { defineStore } from "pinia";
import { isNotAllowedError } from "../utils/errors";

export const getClipboardStore = defineStore("clipboard", {
    state() {
        return {
            // 'clipboard.readText' was removed from Firefox for security reasons
            isSupported: navigator.clipboard && navigator.clipboard.readText,
            UNSUPPORTED_MESSAGE: "Clipboard is not supported in this browser.",
        };
    },
    actions: {
        async writeText(text: string): Promise<boolean> {
            if (!this.isSupported) {
                this.$bus.emit("error", this.UNSUPPORTED_MESSAGE);
                return false;
            }
            try {
                await navigator.clipboard.writeText(text);
                return true;
            } catch (err) {
                if (isNotAllowedError(err)) {
                    this.$bus.emit("error", this.UNSUPPORTED_MESSAGE);
                    return false;
                } else {
                    throw err;
                }
            }
        },
        async readText(): Promise<string | undefined> {
            if (!this.isSupported) {
                this.$bus.emit("error", this.UNSUPPORTED_MESSAGE);
                return;
            }
            try {
                return await navigator.clipboard.readText();
            } catch (error) {
                if (isNotAllowedError(error)) {
                    this.$bus.emit("error", "Clipboard Error: Permissions not set");
                    return;
                }

                this.$bus.emit("error", `Clipboard Error: ${error}`);
                throw error;
            }
        },
    },
});

export type ClipboardStore = ReturnType<typeof getClipboardStore>;
