import Joi from "joi";
import { MAX_STRINGS } from "../constants";
import { defaultStringValidator } from "./default-validators";
import { BarLineType } from "../tablature-event";

const chordModifierValidator = Joi.string().valid("vibrato", "upstroke", "downstroke");

// TODO: Could this come from note.ts?
const noteModifierValidator = Joi.string().valid(
    "hammer-pull",
    "slide",
    "quarter-bend",
    "half-bend",
    "bend",
    "double-bend",
    "quarter-bend-down",
    "half-bend-down",
    "bend-down",
    "double-bend-down",
    "quarter-pre-bend",
    "half-pre-bend",
    "pre-bend",
    "double-pre-bend"
);

const noteValidator = Joi.object({
    fret: [Joi.number(), "x"],
    modifiers: Joi.array().items(noteModifierValidator),
});

const chordValidator = Joi.object({
    notes: Joi.array().items(noteValidator, null).sparse(true).required().max(MAX_STRINGS),
    modifiers: Joi.array().items(chordModifierValidator).unique(),
});

const deprecatedChordValidator = Joi.object({
    notes: Joi.array().items(Joi.number(), "x", null).sparse(true).required().max(MAX_STRINGS),
    modifiers: Joi.array().items(chordModifierValidator).unique(),
    noteModifiers: Joi.array().items(noteModifierValidator, null).sparse(true).max(MAX_STRINGS),
});

const barlineValidator = Joi.string().valid(...Object.values(BarLineType));

const undefinedEventValidation = Joi.object({
    annotation: defaultStringValidator,
});

const chordEventValidation = Joi.object({
    type: Joi.string().valid("chord").required(),
    annotation: defaultStringValidator,
    data: [chordValidator.required(), deprecatedChordValidator.required()],
});

const barlineEventValidation = Joi.object({
    type: Joi.string().valid("barline").required(),
    annotation: defaultStringValidator,
    data: barlineValidator.required(),
});

export const tablatureEventValidator = [undefinedEventValidation, chordEventValidation, barlineEventValidation];
